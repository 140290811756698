
// @ts-check
import { defineComponent } from "vue"

export default defineComponent({
	name: "ComponentTitle",
	props: {
		title: {
			type: String,
			require: true,
			default: "",
		},
		xs: {
			type: Boolean,
			require: false,
		},
	},
})
