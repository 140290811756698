/**
 * デフォルトのmixins
 *
 * methodsをmain.tsのComponentCustomPropertiesに形を追加する
 */
export default {
	methods: {
		eventTargetValue(event: any): string {
			if (!(event.target instanceof HTMLInputElement)) {
				return ""
			}
			return event.target.value
		},
	},
}
