
// @ts-check
import { defineComponent } from "vue"
import { ToiletPaperIcon, ToiletSoapIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentToiletMarker",
	components: {
		ToiletPaperIcon,
		ToiletSoapIcon,
	},
	props: {
		name: {
			type: String,
			default: "",
		},
		usageStatus: {
			type: String,
			default: "",
		},
		facilityType: {
			type: Number,
			default: 0,
		},
		stockStatus: {
			type: String,
			default: "",
		},
		styleProp: {
			type: Object,
		},
		alert: {
			type: Boolean,
		},
	},
	data() {
		return {
			state: {
				isAlertShow: false,
			},
			alertToggleTimer: 0,
		}
	},
	mounted() {
		// アラートがあったら「アラート」点滅表示する
		if (this.alert === true) {
			this.state.isAlertShow = true
			this.alertToggleTimer = setInterval(() => {
				this.state.isAlertShow = !this.state.isAlertShow
			}, 2000)
		}
	},
	watch: {
		// アラートが更新された際に「アラート」点滅表示を切り替える
		alert(val): void {
			if (val === true) {
				this.state.isAlertShow = true
				this.alertToggleTimer = setInterval(() => {
					this.state.isAlertShow = !this.state.isAlertShow
				}, 2000)
			} else {
				this.state.isAlertShow = false
				clearInterval(this.alertToggleTimer)
			}
		},
	},
	unmounted() {
		clearInterval(this.alertToggleTimer)
	},
})
