import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d59552d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "c-button-breadCrumb" }
const _hoisted_3 = { class: "c-button-here" }
const _hoisted_4 = { class: "c-button-breadCrumb" }
const _hoisted_5 = { class: "c-button-here" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToStations()))
      }, "駅一覧")
    ]),
    (this.$breadCrumb.page === 'station')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", null, [
            _createVNode(_component_ChevronRightIcon)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToStation(this.$breadCrumb.station_id)))
            }, _toDisplayString(this.$breadCrumb.station_name), 1)
          ])
        ], 64))
      : _createCommentVNode("", true),
    (this.$breadCrumb.page === 'toilet')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", null, [
            _createVNode(_component_ChevronRightIcon)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToStation(this.$breadCrumb.station_id)))
            }, _toDisplayString(this.$breadCrumb.station_name), 1)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_ChevronRightIcon)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToToilet(this.$breadCrumb.toilet_id)))
            }, _toDisplayString(this.$breadCrumb.toilet_name), 1)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}