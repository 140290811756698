import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_remove_icon = _resolveComponent("remove-icon")!

  return (_ctx.count === 0 || Number.isNaN(_ctx.count))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_remove_icon)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_ctx.count), 1)
      ]))
}