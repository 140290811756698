
import { defineComponent } from "vue"

export default defineComponent({
	name: "AccountIcon",
	props: {
		fill: {
			type: String,
			default: "##017afc",
		},

		width: {
			type: String,
			default: "20",
		},

		height: {
			type: String,
			default: "20",
		},
	},
})
