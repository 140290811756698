/* eslint-disable prettier/prettier */
import { defineStore } from "pinia";
import { stationAlert } from "@/components/types/stationAlert";

export interface IgnoreAlert {
	stationName: string
	alertName: string
}

// アラートモーダルストアインターフェース
export interface AlertModalStoreType {
	// 通知を表示するか
	display: boolean

	// 発報しているアラート
	alerts: stationAlert[] | null

	// アラート音再生タイプ
	repeatType: number

	// アラート音を再生するか
	isPlay: boolean

	// 通知を無視するアラートリスト
	ignoreAlertList: IgnoreAlert[]
	
	// 確認モーダルを確認したか
	isConfirm: boolean
}

/**
 * アラートモーダルストア
 */
export const AlertModalStore = defineStore("alertModal", {
	state: () =>
		({
			display: false,
			alerts: null,
			repeatType: 0,
			isPlay: false,
			isConfirm: false,
			ignoreAlertList: [],
		} as AlertModalStoreType),
})
