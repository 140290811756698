import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7b752ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TableContainerStations" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "l-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_title = _resolveComponent("v-title")!
  const _component_v_db_time_latest = _resolveComponent("v-db-time-latest")!
  const _component_v_is_controlled = _resolveComponent("v-is-controlled")!
  const _component_v_paper = _resolveComponent("v-paper")!
  const _component_v_soap = _resolveComponent("v-soap")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_progress_circle_icon = _resolveComponent("progress-circle-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_title, { title: _ctx.title }, null, 8, ["title"]),
    _createVNode(_component_v_db_time_latest, {
      latestTime: _ctx.latestTime,
      isFetching: _ctx.state.dbTimeLatest.isFetching,
      isFetched: _ctx.state.dbTimeLatest.isFetched
    }, null, 8, ["latestTime", "isFetching", "isFetched"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.state.stations.isFetched)
        ? (_openBlock(), _createBlock(_component_v_table, {
            key: 0,
            headers: _ctx.table.headers,
            "on-row-click": _ctx.onRowClick
          }, {
            rows: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.rows, (row, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index,
                  class: "table-row",
                  onClick: ($event: any) => (_ctx.onRowClick(row))
                }, [
                  _createElementVNode("td", null, _toDisplayString(row.lineName), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_v_is_controlled, {
                      isControlled: Boolean(row.isControlled)
                    }, null, 8, ["isControlled"])
                  ]),
                  _createElementVNode("td", null, _toDisplayString(row.stationName), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_v_paper, {
                      paper: row.paper,
                      paperStockCount: row.paperStockCount,
                      paperCapacityCount: row.paperCapacityCount
                    }, null, 8, ["paper", "paperStockCount", "paperCapacityCount"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_v_soap, {
                      soap: row.soap,
                      soapStockCount: row.soapStockCount,
                      soapCapacityCount: row.soapCapacityCount
                    }, null, 8, ["soap", "soapStockCount", "soapCapacityCount"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_v_alert, {
                      alert: row.alert
                    }, null, 8, ["alert"])
                  ])
                ], 8, _hoisted_2))
              }), 128))
            ]),
            _: 1
          }, 8, ["headers", "on-row-click"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.state.stations.isFetching)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_progress_circle_icon, {
                    width: 40,
                    height: 40
                  })
                ]))
              : _createCommentVNode("", true)
          ], 64))
    ])
  ], 64))
}