import { RouteRecordRaw } from "vue-router"
import MainLayout from "@/layouts/MainLayout.vue"
import ToiletDetailsView from "@/pages/toilet-details/View.vue"

export default [
	{
		path: "/station/:id/toilet-details/:toiletId",
		meta: {
			protected: true,
		},
		component: MainLayout,
		children: [
			{
				path: "",
				name: "toilet-details-view",
				component: ToiletDetailsView,
			},
		],
	},
] as Array<RouteRecordRaw>
