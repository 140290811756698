export const sortAlerts = (alerts: string[]) => {
	if (alerts.length === 0 || alerts.length === 1) {
		return alerts
	}
	
	// ソート順マスタ配列
	const orderArray = ['CLEANING_REQUIRED','CONTAMINATED','LONG_STAY','SENSOR_UNRESPONSIVE','PAPER_STOLEN','WATER_STOLEN','OVERFLOW','FALL_DOWN']

	// ソート
	const sortedArray = alerts.slice().sort(function (a, b) {
		return orderArray.indexOf(a) - orderArray.indexOf(b);
	});
	
	// ソート結果を返却
	return sortedArray
}