
import http from "@/utils/http"
import cookies from "@/utils/cookies"
import { AccountIcon, AccountFocusIcon } from "@/utils/icons"
import { defineComponent } from "vue"
import { vBreadCrumbs, vNotification, vModal } from "@/utils/components"
import { logoutRouter } from "../router"

export default defineComponent({
	name: "MainLayout.vue",
	components: {
		AccountIcon,
		AccountFocusIcon,
		vBreadCrumbs,
		vNotification,
		vModal,
	},
	data() {
		return {
			state: {
				isDropDown: false,
				isHover: false,
				isFocus: false,
			},
			user: {
				email: "",
			},
		}
	},
	methods: {
		mouseOverAction() {
			this.state.isHover = true
			this.state.isFocus = true
		},
		mouseLeaveAction() {
			this.state.isHover = false
			if (!this.state.isDropDown) {
				this.state.isFocus = false
			}
		},
		hideDropdown(): void {
			this.state.isFocus = false
			setTimeout(() => {
				this.state.isDropDown = false
			}, 200)
		},
		logoutUser(): void {
			http.delete("auth/signout").then(() => {
				cookies.del("vn_access_token")
				cookies.del("vn_refresh_token")
				this.$currentUser.reset()
				logoutRouter()

				if (
					!cookies.get("vn_access_token") &&
					!cookies.get("vn_refresh_token")
				) {
					this.$router.push({ name: "login-index" })
				} else {
					alert("failed to delete Cookies! check this error")
				}
			})
		},
	},
})
