import { RouteRecordRaw } from "vue-router"
import MainLayout from "@/layouts/MainLayout.vue"
import StationsIndex from "@/pages/stations/Index.vue"
import StationView from "@/pages/stations/View.vue"

export default [
	{
		path: "/stations",
		meta: {
			protected: true,
		},
		component: MainLayout,
		children: [
			{
				path: "",
				name: "stations",
				component: StationsIndex,
			},
		],
	},
	{
		path: "/station/:id",
		meta: {
			protected: true,
		},
		component: MainLayout,
		children: [
			{
				path: "",
				name: "station-view",
				component: StationView,
			},
		],
	},
] as Array<RouteRecordRaw>
