/**
 *
 * @param name
 * @param value
 * @param daysToLive
 */
const set = function (name: string, value: string, daysToLive: number): void {
	// Encode value in order to escape semicolons, commas, and whitespace
	let cookie = name + "=" + encodeURIComponent(value)
	/* Sets the max-age attribute so that the cookie expires
		after the specified number of days */
	cookie += "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/"
	document.cookie = cookie
}

/**
 *
 * @param name
 */
const get = function (name: string): null | string {
	// Split cookie string and get all individual name=value pairs in an array
	const cookieArr = document.cookie.split(";")

	// Loop through the array elements
	for (let i = 0; i < cookieArr.length; i++) {
		const cookiePair = cookieArr[i].split("=")

		/* Removing whitespace at the beginning of the cookie name
		and compare it with the given string */
		if (name == cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1])
		}
	}
	// Return null if not found
	return null
}

// const update = function (name: string): void {
// 	// Get cookie using our custom function
// 	// let cookie = get(name)
// }

/**
 *
 * @param name
 * @param domain
 */
const del = function (name: string): void {
	// Deleting a cookie
	// This function will attempt to remove a cookie from all paths.
	const pathBits = location.pathname.split("/")
	let pathCurrent = " path="

	// do a simple pathless delete first.
	document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;"

	for (let i = 0; i < pathBits.length; i++) {
		pathCurrent += (pathCurrent.substr(-1) != "/" ? "/" : "") + pathBits[i]
		document.cookie =
			name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";"
	}
}

export default { set, get, del }
