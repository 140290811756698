
import { defineComponent } from "vue"
import { AxiosError, AxiosResponse } from "axios"
import {
	vTitle,
	vDbTimeLatest,
	vStationTable,
	vToiletType,
	vUsageStatus,
	vStock,
	vCubiclesAlert,
	vUsageCount,
	vToiletMarker,
	ChartJs,
} from "@/utils/components"
import {
	RemoveIcon,
	RadioOnIcon,
	RadioOffIcon,
	ProgressCircleIcon,
} from "@/utils/icons"
import { CubicleCol } from "@/components/types/cubicle"
import { stationAlert } from "@/components/types/stationAlert"
import { IgnoreAlert } from "@/store/alertModal"
import { sortAlerts } from "@/utils/sortAlerts"

export default defineComponent({
	name: "ToiletDetailsView.vue",
	components: {
		vTitle,
		vDbTimeLatest,
		vStationTable,
		vToiletType,
		vUsageStatus,
		vStock,
		vCubiclesAlert,
		vUsageCount,
		RemoveIcon,
		RadioOnIcon,
		RadioOffIcon,
		vToiletMarker,
		ProgressCircleIcon,
		ChartJs,
	},
	async created() {
		// トイレ詳細画面にアクセスした際に閲覧権限があるか確認する
		const isAllow = await this.isAllowStation()
		if (isAllow === true) {
			/** notificationの初期化 */
			this.$notification.display = false
			this.$notification.type = "actionSuccess"
			this.$notification.message = ""

			/** breadCrumbの初期化 */
			this.$breadCrumb.page = "toilet"

			/** alertModalの初期化 */
			this.$alertModal.display = false
			this.$alertModal.alerts = null
			this.$alertModal.repeatType = 0
			this.$alertModal.isPlay = false

			await this.getToilet()
			await this.fetchDbTimeLatest()
			await this.getToiletGraphChart()

			// トイレ別でソート
			this.sortRows(this.state.toilet.sortType)
		} else {
			this.$router.push({ name: "stations" })
		}
	},
	data() {
		return {
			latestTime: 0,
			cubicles: [],
			stationId: "",
			stationName: "",
			toiletId: null,
			toiletMapId: null,
			toiletName: "",
			graphData: [],
			toiletImage: "",
			toiletImageBlobUrl: "",
			toiletImageMeta: {},
			updateTimer: 0,
			state: {
				dbTimeLatest: {
					isFetching: false,
					isFetched: false,
				},
				map: {
					isMap: false,
					/* State that represent if the map is rendered or not*/
					isRendering: false,
					isRendered: false,
					calculatedHeight: 0,
					maxXCoordinates: 0,
					maxRightXCoordinates: 724,
				},
				toilet: {
					isFetching: false,
					isFetched: false,
					// ソート順を管理する 0:トイレ別 1:ストック順 2:アラート発生順 3:使用状況
					sortType: 0,
				},
				graph: {
					isFetching: false,
					isFetched: false,
					isEmpty: false,
				},
				cleaningData: {
					isFetching: false,
				},
			},

			cubiclesTable: {
				headers: [
					{ label: "名称", field: "name", width: "6%" },
					{ label: "種別", field: "facilityType", width: "6%" },
					{ label: "性別", field: "toiletMultipInfo", width: "6%" },
					{ label: "使用状況", field: "facilityStatus", width: "12%" },
					{ label: "ストック", field: "stockStatus", width: "14%" },
					{ label: "アラート", field: "alert", width: "14%" },
					{ label: "使用回数", field: "usageCount", width: "6%" },
					{ label: "清掃記録", field: "cleanLog", width: "24%" },
				],
				rows: [] as Array<CubicleCol>,
				hidden: ["facilityId", "timelatest"],
			},
			withSortCubiclesTable: {
				rows: [] as Array<CubicleCol>,
			},
		}
	},
	methods: {
		async fetchDbTimeLatest(): Promise<void> {
			this.state.dbTimeLatest.isFetching = true

			/* Fetch GetTimeLatest API */
			const query = await this.$http.get("/stations/lastupdated")
			// success
			if (query.status === 200) {
				this.latestTime = query.data.latestTime
				this.state.dbTimeLatest.isFetching = false
				if (!this.state.dbTimeLatest.isFetched) {
					this.state.dbTimeLatest.isFetched = true
				}
			}
		},
		async getToilet(): Promise<void> {
			this.state.toilet.isFetching = true

			const toiletId = this.$route.params.toiletId
			let response: AxiosResponse = await this.getToiletDetails(toiletId)
			if (response.data.stationId !== Number(this.$route.params.id)) {
				this.$router.push({ name: "stations" })
				return
			}
			if (response.status !== 200) {
				return
			}
			this.withSortCubiclesTable.rows = response.data.cubicles
			this.stationId = response.data.stationId
			this.stationName = response.data.stationName || ""
			this.toiletId = response.data.toiletId
			this.toiletMapId = response.data.toiletMapId
			this.toiletName = response.data.toiletName || ""
			this.$breadCrumb.station_id = response.data.stationId
			this.$breadCrumb.station_name = response.data.stationName || ""
			this.$breadCrumb.toilet_id = response.data.stationId
			this.$breadCrumb.toilet_name = response.data.toiletName || ""
			this.state.toilet.isFetching = false
			if (!this.state.toilet.isFetched) {
				this.state.toilet.isFetched = true
			}

			if (response.data.isDisplay) {
				// アラートがあったらalertModalを表示する
				const stationAlert: stationAlert = {
					stationName: response.data.stationName,
					alerts: [],
				}
				if (!response.data.cubicles || !response.data.cubicles.length) {
					return
				}
				response.data.cubicles.map((cubicle: any) => {
					if (!cubicle.alertArray || !cubicle.alertArray.length) {
						return
					}
					const alerts = [] as any
					cubicle.alertArray.map((alert: any) => {
						switch (alert.alert) {
							case "CLEANING_REQUIRED":
								if (
									!!response.data.alertNotification.CLEANING_REQUIRED &&
									response.data.alertNotification.CLEANING_REQUIRED !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "CONTAMINATED":
								if (
									!!response.data.alertNotification.CONTAMINATED &&
									response.data.alertNotification.CONTAMINATED !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "LONG_STAY":
								if (
									!!response.data.alertNotification.LONG_STAY &&
									response.data.alertNotification.LONG_STAY !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "SENSOR_UNRESPONSIVE":
								if (
									!!response.data.alertNotification.SENSOR_UNRESPONSIVE &&
									response.data.alertNotification.SENSOR_UNRESPONSIVE !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "DAMAGE_STOLEN":
								if (
									!!response.data.alertNotification.PAPER_STOLEN &&
									response.data.alertNotification.PAPER_STOLEN !== 0 &&
									cubicle.facilityType === 1
								) {
									alerts.push("PAPER_STOLEN")
								}
								if (
									!!response.data.alertNotification.WATER_STOLEN &&
									response.data.alertNotification.WATER_STOLEN !== 0 &&
									cubicle.facilityType !== 1
								) {
									alerts.push("WATER_STOLEN")
								}
								break
							case "OVERFLOW":
								if (
									!!response.data.alertNotification.OVERFLOW &&
									response.data.alertNotification.OVERFLOW !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "FALL_DOWN":
								if (
									!!response.data.alertNotification.FALL_DOWN &&
									response.data.alertNotification.FALL_DOWN !== 0
								) {
									alerts.push(alert.alert)
								}
								break
						}
					})
					alerts.map((alert: any) => {
						if (stationAlert.alerts.includes(alert)) {
							return
						}
						stationAlert.alerts.push(alert)
					})
				})

				// モーダルに表示するアラートをソートする
				stationAlert.alerts = sortAlerts(stationAlert.alerts)

				// ignoreAlertListにあるアラートを除外する
				if (stationAlert.alerts.length) {
					const ignoreAlertList = this.$alertModal.ignoreAlertList
					const alerts = stationAlert.alerts.filter((alertName) => {
						return ignoreAlertList.every((ignoreAlert) => {
							return alertName !== ignoreAlert.alertName
						})
					})
					this.$alertModal.ignoreAlertList =
						this.$alertModal.ignoreAlertList.concat(
							alerts.map((alert) => {
								return {
									stationName: response.data.stationName,
									alertName: alert,
								}
							})
						)
					// アラートモーダルに表示するアラートがあった場合
					if (alerts.length !== 0) {
						let repeatType = 0
						alerts.map((alert) => {
							switch (response.data.alertNotification[alert]) {
								case 1:
									repeatType = 1
									break
								case 2:
									if (repeatType !== 1) {
										repeatType = 2
									}
									break
								default:
									break
							}
						})

						this.$alertModal.display = true
						const isPaper = alerts.includes("PAPER_STOLEN")
						const isWater = alerts.includes("WATER_STOLEN")
						if (isPaper && isWater) {
							alerts.splice(alerts.indexOf("PAPER_STOLEN"), 1)
						}
						this.$alertModal.alerts = [
							{
								stationName: response.data.stationName,
								alerts: alerts,
							},
						]
						this.$alertModal.repeatType = repeatType
						if (repeatType !== 0) {
							this.$alertModal.isPlay = true
						}
					}
				}
			}

			// アラートがなくなったら、ignoreAlertListからなくなったアラートを削除する
			const alertAll: IgnoreAlert[] = []
			if (response.data.cubicles && response.data.cubicles.length) {
				response.data.cubicles.map((cubicle: any) => {
					if (!cubicle.alertArray || !cubicle.alertArray.length) {
						return
					}
					cubicle.alertArray.map((alert: any) => {
						let currentAlert
						if (alert.alert === "DAMAGE_STOLEN") {
							if (cubicle.facilityType === 1) {
								currentAlert = {
									stationName: response.data.stationName,
									alertName: "PAPER_STOLEN",
								}
							} else {
								currentAlert = {
									stationName: response.data.stationName,
									alertName: "WATER_STOLEN",
								}
							}
						} else {
							currentAlert = {
								stationName: response.data.stationName,
								alertName: alert.alert,
							}
						}
						if (alertAll.includes(currentAlert)) {
							return
						}
						alertAll.push(currentAlert)
					})
				})
				this.$alertModal.ignoreAlertList =
					this.$alertModal.ignoreAlertList.filter((ignoreAlert) => {
						return alertAll.some((alert) => {
							return (
								alert.stationName === ignoreAlert.stationName &&
								alert.alertName === ignoreAlert.alertName
							)
						})
					})
			}

			// cubiclesTable.rows.length > 0ならstate.map.maxXCoordinatesを更新する
			if (this.cubiclesTable.rows.length > 0) {
				for (let i = 0; i < this.cubiclesTable.rows.length; i++) {
					if (this.cubiclesTable.rows[i].xCoordinates !== undefined) {
						if (
							this.state.map.maxXCoordinates <
							Number(this.cubiclesTable.rows[i].xCoordinates)
						) {
							this.state.map.maxXCoordinates = Number(
								this.cubiclesTable.rows[i].xCoordinates
							)
						}
					}
				}
			}
			if (!this.state.map.isRendered) {
				if (
					response.data.toiletMapId === undefined ||
					response.data.toiletMapId === null
				) {
					this.state.map.isMap = false
				} else {
					this.state.map.isMap = true
					await this.getToiletImageMap(response.data.toiletMapId)
				}
			}
		},
		async getToiletDetails(
			toiletId: string | string[]
		): Promise<AxiosResponse> {
			return await this.$http.get("/client/toilet/usage/" + toiletId)
		},
		async getToiletImageMap(toiletMapId: number) {
			await this.$http
				.get("toilet-maps/map/" + toiletMapId + "/s3", {
					responseType: "blob",
					timeout: 40000,
				})
				.then((img) => {
					this.loadFromBlob(new File([img.data], "fileName"))
				})
				.catch((e) => {
					console.log(e)
				})
		},
		async getToiletGraphChart(): Promise<void> {
			this.state.graph.isFetching = true

			const id = this.$route.params.toiletId
			if (id) {
				const query = await this.$http.get("/client/toilet/graph/" + id)
				if (query.status === 200) {
					this.graphData = query.data
					this.state.graph.isFetching = false
					if (!this.state.graph.isFetched) {
						this.state.graph.isFetched = true
					}

					// graphDataが空の場合はグラフ表示をしないようstate.graph.isEmptyをtrueにする
					if (!Object.keys(this.graphData).length) {
						this.state.graph.isEmpty = true
					}
				}
			}
		},
		async postCleanToiletData(
			sensorId: number,
			cleanType: string,
			isDevice: boolean
		) {
			if (!this.state.cleaningData.isFetching) {
				const user_id = this.$currentUser.user_id
				if (user_id) {
					try {
						this.state.cleaningData.isFetching = true
						const query = await this.$http.post(
							"/toilets/cleaning/" + sensorId + "/" + cleanType,
							{
								user: user_id,
								isDevice: isDevice,
							}
						)
						if (query.status === 200) {
							this.$notification.display = true
							this.$notification.type = "actionSuccess"
							if (cleanType === "start") {
								this.$notification.message = "開始指示をしました"
							} else {
								this.$notification.message = "完了登録をしました"
							}
							// rowを更新する
							await this.getToilet()
							await this.fetchDbTimeLatest()
							await this.getToiletGraphChart()
							// 並び順に並べる
							this.sortRows(this.state.toilet.sortType)
						}
					} catch (e) {
						const error = e as AxiosError
						if (error.response) {
							this.$notification.display = true
							this.$notification.type = "error"
							this.$notification.message =
								"システムエラーが発生しました。しばらくしてから再度実行してください。"
						}
					}
				}
				// state.cleaningData.isFetchingを更新し、清掃指示ボタンを押下できるようにする
				this.state.cleaningData.isFetching = false
			}
		},
		async isAllowStation(): Promise<boolean> {
			const id = this.$route.params.id
			if (id) {
				const query = await this.$http.get("/client/stations/affiliation/" + id)
				if (query.status === 200) {
					return query.data.is
				} else {
					return false
				}
			} else {
				return false
			}
		},
		loadFromBlob(file: any) {
			this.previewImage(file)
		},
		previewImage(files: any) {
			// Check if file exists
			if (files) {
				// Set Toilet map into loading status
				// this.state.map.isRendering = true

				let image = new Image()
				const objectUrl = URL.createObjectURL(files)

				// eslint-disable-next-line @typescript-eslint/no-this-alias
				let self = this
				image.onload = function () {
					// Resize the draggable area
					let mapArea = self.$refs["map-area"] as HTMLDivElement

					const targetWidth = 766
					const percent = targetWidth / image.width
					const calculatedHeight = image.height * percent

					self.state.map.calculatedHeight = calculatedHeight

					mapArea.style.height = `${calculatedHeight}px`

					// dropArea.style.width = `${this.width}px`
					mapArea.style.backgroundImage = `url('${objectUrl}')`
					mapArea.style.width = "100%"
					mapArea.style.backgroundPosition = "center center"
					mapArea.style.backgroundRepeat = "no-repeat"
					mapArea.style.backgroundSize = "cover"

					// Flag as rendered
					setTimeout(() => {
						self.state.map.isRendering = false
						self.state.map.isRendered = true
					}, 300)
				}

				// // Initialize Image
				image.src = objectUrl
			}
		},
		sortRowsDefault() {
			// toiletMapIconId昇順でソート
			let sortRows = this.withSortCubiclesTable.rows
			return sortRows.sort((a, b) => {
				return a.toiletMapIconId < b.toiletMapIconId ? -1 : 1
			})
		},
		sortRows(sortType: number) {
			// this.withSortCubiclesTable.rowsがない場合にソートを行うとエラーが起こるためreturnする
			if (!this.withSortCubiclesTable.rows) {
				return
			}

			// state.toilet.sortTypeを更新
			this.state.toilet.sortType = sortType

			// rowsの並びを初期化
			let sortCubicleRows = this.sortRowsDefault()

			// sortTypeごとに処理を切り分け
			switch (sortType) {
				case 0:
					break
				case 1:
					// 使用状況でソート
					// 使用状況が満同士、使用分数があるの場合は使用時間降順に並べる
					sortCubicleRows.sort((a, b) => {
						return a.facilityStatus === "occupied" &&
							b.facilityStatus === "occupied" &&
							!!a.usageMinute &&
							!!b.usageMinute
							? Number(a.usageMinute) - Number(b.usageMinute)
							: a.facilityStatus === "occupied" && b.facilityStatus === "vacant"
							? 1
							: -1
					})
					// 個室を先頭に表示し、それ以外をfacilityTypeでソート
					sortCubicleRows.sort((a, b) => {
						return a.facilityType === 1 ? -1 : a.facilityType - b.facilityType
					})
					break
				case 2:
					// アラート(refill or warning)なら先頭に表示
					sortCubicleRows.sort((a) => {
						return (a.facilityType === 1 &&
							(a.paperStatus === "refill" || a.paperStatus === "warning")) ||
							((a.facilityType === 2 || a.facilityType === 6) &&
								(a.soapStatus === "refill" || a.soapStatus === "warning"))
							? 1
							: -1
					})
					// アラートが上がっている行同士でstockCountを比較
					sortCubicleRows.sort((a, b) => {
						return (a.facilityType === 1 &&
							(a.paperStatus === "refill" || a.paperStatus === "warning") &&
							b.facilityType === 1 &&
							(b.paperStatus === "refill" || b.paperStatus === "warning")) ||
							(a.facilityType === 1 &&
								(a.paperStatus === "refill" || a.paperStatus === "warning") &&
								(b.facilityType === 2 || b.facilityType === 6) &&
								(b.soapStatus === "refill" || b.soapStatus === "warning")) ||
							((a.facilityType === 2 || a.facilityType === 6) &&
								(a.soapStatus === "refill" || a.soapStatus === "warning") &&
								b.facilityType === 1 &&
								(b.paperStatus === "refill" || b.paperStatus === "warning")) ||
							((a.facilityType === 2 || a.facilityType === 6) &&
								(a.soapStatus === "refill" || a.soapStatus === "warning") &&
								(b.facilityType === 2 || b.facilityType === 6) &&
								(b.soapStatus === "refill" || b.soapStatus === "warning"))
							? b.stockCount - a.stockCount
							: 0
					})
					// facilityTypeが5なら末尾に表示
					sortCubicleRows.sort((a) => {
						return a.facilityType === 5 ? 1 : -1
					})
					break
				case 3:
					{
						// アラート発生時間でソート
						// アラートで最も古い時間をrows.timeLatestに格納
						// rowごとにtimeLatestでソート
						let NewArray = this.withSortCubiclesTable.rows
						for (let i = 0; i < NewArray.length; i++) {
							const sortedAlertTime =
								NewArray[i].alertArray !== undefined
									? NewArray[i].alertArray
									: []
							if (sortedAlertTime.length > 0) {
								const newAlert = sortedAlertTime.sort((a, b) => {
									return a.time < b.time ? -1 : 1
								})
								NewArray[i].timeLatest = newAlert[0].time
							} else {
								NewArray[i].timeLatest = ""
							}
						}
						sortCubicleRows = NewArray.sort((a, b) => {
							return (
								(a.timeLatest === "" ? 1 : 0) - (b.timeLatest === "" ? 1 : 0) ||
								+(a.timeLatest > b.timeLatest) ||
								-(a.timeLatest < b.timeLatest)
							)
						})
					}
					break
				default:
					break
			}
			this.cubiclesTable.rows = sortCubicleRows
		},
	},
	async mounted() {
		this.updateTimer = setInterval(async () => {
			await this.getToilet()
			await this.fetchDbTimeLatest()
			await this.getToiletGraphChart()

			// 並び順に並べる
			this.sortRows(this.state.toilet.sortType)
		}, 30000)
	},
	unmounted() {
		clearInterval(this.updateTimer)
	},
})
