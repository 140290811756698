
// @ts-check
import { defineComponent } from "vue"
import { Field, useField } from "vee-validate"
import * as validate from "@/utils/validate"

export default defineComponent({
	name: "ComponentInput",
	components: {
		Field,
	},
	props: {
		// v-model / input.value
		value: {
			type: null,
			required: true,
		},
		// input.name
		name: {
			type: String,
			required: false,
			default: () => {
				return "input-" + Math.floor(Math.random() * 10000)
			},
		},
		// input.placeholder
		placeholder: {
			type: String,
			required: false,
			default: "",
		},
		// ラベル名
		label: {
			type: String,
			required: false,
			default: "",
		},
		// input.class
		customClass: {
			type: String,
			required: false,
			default: "",
		},
		// ルール定義
		rules: {
			type: null,
			required: false,
		},
		// input.type
		type: {
			type: String,
			required: false,
			default: "text",
		},
		// inputの親要素のstyle
		formGroupStyle: {
			type: Array,
			required: false,
			default: () => [],
		},
		// エラーメッセージを表示する
		showErrorMessage: {
			type: Boolean,
			required: false,
			default: true,
		},
		// マニュアルのエラーを表示するか
		manualErrorTrigger: {
			type: Boolean,
			required: false,
			default: false,
		},
		// マニュアルのエラーメッセージ
		manualErrorMessage: {
			type: String,
			required: false,
			default: undefined,
		},
	},
	data() {
		const field = useField(this.name)

		// ルール定義
		let fieldRules = this.rules
		if (typeof this.rules === "string" && this.rules === "required") {
			fieldRules = validate.required(this.label)
		}

		return {
			// input.type
			inputType: this.type,
			// toggleがtrueの時、typeをtextに変更させる。type=passwordなどで使用する。
			passwordToggle: false,
			// ルール定義
			fieldRules,
			// エラー配列
			fieldErrors: field.errors,
		}
	},
	methods: {
		// passwordなどを一時的に表示する
		passwordToggleVisible(): void {
			this.passwordToggle = !this.passwordToggle
			this.inputType = this.passwordToggle ? "text" : this.type
		},
		// エラーがあるか
		isError(): boolean {
			return this.fieldErrors.length !== 0 || this.manualErrorTrigger
		},
	},
})
