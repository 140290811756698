
import { defineComponent } from "vue"
import { DoorIcon, SmallIcon, WashHandIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentToiletType",
	components: {
		DoorIcon,
		SmallIcon,
		WashHandIcon,
	},
	props: {
		toiletType: {
			type: Number,
			default: 1,
		},
	},
})
