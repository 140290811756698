
import { defineComponent } from "vue"
import { vButton } from "@/utils/components"
import { stationAlert } from "@/components/types/stationAlert"

export default defineComponent({
	name: "ComponentModal",
	components: { vButton },
	props: {
		isActive: {
			type: Boolean,
			required: false,
			default: false,
		},
		alerts: {
			type: Array as () => stationAlert[] | null,
			default: () => [],
		},
		repeatType: {
			type: Number,
			required: false,
			default: 0,
		},
		isPlay: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	watch: {
		isActive(newValue, oldValue) {
			// isActiveがtrueになったら、再生開始
			if (newValue === true && oldValue === false) {
				// アラート表示になった際アラート音を再生
				if (this.isPlay) {
					this.startAudio(1)
				}
			}
		},
	},
	methods: {
		closeModal() {
			this.$alertModal.display = false
			this.stopAudio()
			this.$emit("update:isPlay", false)
		},
		closeConfirmModal() {
			this.$alertModal.isConfirm = true
			if (this.isPlay) {
				this.startAudio(0)
			}
		},
		translate(alert: string) {
			let alertText = ""
			switch (alert) {
				case "CLEANING_REQUIRED":
					alertText = "要清掃"
					break
				case "CONTAMINATED":
					alertText = "汚損"
					break
				case "LONG_STAY":
					alertText = "長期滞在"
					break
				case "SENSOR_UNRESPONSIVE":
					alertText = "センサ反応なし"
					break
				case "PAPER_STOLEN":
					alertText = "破損/盗難"
					break
				case "WATER_STOLEN":
					alertText = "破損/盗難"
					break
				case "OVERFLOW":
					alertText = "あふれ"
					break
				case "FALL_DOWN":
					alertText = "体調不良"
					break
			}
			return alertText
		},
		startAudio(volume: number) {
			const audioPlayer = this.$refs.audioPlayer as HTMLAudioElement
			audioPlayer.pause()
			audioPlayer.currentTime = 0
			audioPlayer.volume = volume
			setTimeout(function () {
				audioPlayer.play()
			}, 1000)
		},
		stopAudio() {
			const audioPlayer = this.$refs.audioPlayer as HTMLAudioElement
			audioPlayer.pause()
		},
	},
})
