import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-412ff635"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: { display: 'flex' } }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "table-wrapper block",
      style: _normalizeStyle({ maxWidth: _ctx.tableWidth, height: _ctx.tableHeight })
    }, [
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("thead", null, [
          _renderSlot(_ctx.$slots, "c-table-thead", {}, undefined, true),
          (_ctx.headers.length && !_ctx.$slots.thead)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, i) => {
                  return (_openBlock(), _createElementBlock("th", {
                    class: "c-table-header",
                    key: i,
                    scope: "col",
                    style: _normalizeStyle({
									minWidth: header.width,
									width: header.width,
								})
                  }, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(header.label), 1)
                  ], 4))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("tbody", null, [
          _renderSlot(_ctx.$slots, "rows", {}, undefined, true)
        ])
      ])
    ], 4)
  ]))
}