
import { defineComponent } from "vue"

export default defineComponent({
	name: "RemoveIcon",
	props: {
		fill: {
			type: String,
			default: "000000",
		},

		width: {
			type: Number,
			default: 20,
		},

		height: {
			type: Number,
			default: 20,
		},
	},
})
