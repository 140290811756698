
import { defineComponent } from "vue"
import {
	vTitle,
	vDbTimeLatest,
	vStationTable,
	vToiletType,
	vUsageStatus,
	vStock,
	vCubiclesAlert,
	vUsageCount,
	vAlert,
	StationChartJs,
} from "@/utils/components"
import {
	RemoveIcon,
	RadioOnIcon,
	RadioOffIcon,
	ProgressCircleIcon,
} from "@/utils/icons"
import { CubicleCol } from "@/components/types/cubicle"
import { ToiletCol } from "@/components/types/toilet"
import { stationAlert } from "@/components/types/stationAlert"
import { sortAlerts } from "@/utils/sortAlerts"
import { IgnoreAlert } from "@/store/alertModal"

export default defineComponent({
	name: "StationView.vue",
	components: {
		vTitle,
		vDbTimeLatest,
		vStationTable,
		vToiletType,
		vUsageStatus,
		vStock,
		vCubiclesAlert,
		vUsageCount,
		RemoveIcon,
		vAlert,
		RadioOnIcon,
		RadioOffIcon,
		ProgressCircleIcon,
		StationChartJs,
	},
	async created() {
		// 駅詳細画面にアクセスした際に閲覧権限があるか確認する
		const isAllow = await this.isAllowStation()
		if (isAllow === true) {
			/** breadCrumbを初期化する */
			this.$breadCrumb.page = "station"
			this.$breadCrumb.station_id = null
			this.$breadCrumb.station_name = ""
			this.$breadCrumb.toilet_id = null
			this.$breadCrumb.toilet_name = ""

			/** alertModalの初期化 */
			this.$alertModal.display = false
			this.$alertModal.alerts = null
			this.$alertModal.repeatType = 0
			this.$alertModal.isPlay = false

			await this.fetchDbTimeLatest()
			await this.getStationUsage()
			await this.getStationGraphChart()
			// トイレ別、facilityId 降順にソートする
			this.sortRows(this.state.station.sortType)
		} else {
			this.$router.push({ name: "stations" })
		}
	},
	data() {
		return {
			latestTime: 0,
			cubicles: [],
			stationId: "",
			stationName: "",
			toilets: [],
			isCubicles: true,
			updateTimer: 0,
			state: {
				station: {
					isFetching: false,
					isFetched: false,
					sortType: 0,
				},
				graph: {
					isFetching: false,
					isFetched: false,
					graphType: 0,
				},
				dbTimeLatest: {
					isFetching: false,
					isFetched: false,
				},
				cleaningData: {
					isFetching: false,
				},
			},
			graphData: [],
			selectedGraphData: [],

			cubiclesTable: {
				headers: [
					{ label: "名称", field: "name", width: "6%" },
					{ label: "トイレ", field: "toiletName", width: "12%" },
					{ label: "種別", field: "facilityType", width: "6%" },
					{ label: "性別", field: "toiletMultipInfo", width: "6%" },
					{ label: "使用状況", field: "facilityStatus", width: "12%" },
					{ label: "ストック", field: "stockStatus", width: "14%" },
					{ label: "アラート", field: "alert", width: "14%" },
					{ label: "使用回数", field: "usageCount", width: "6%" },
					{ label: "清掃記録", field: "cleanLog", width: "24%" },
				],
				rows: [] as Array<CubicleCol>,
			},
			withSortCubiclesTable: {
				rows: [] as Array<CubicleCol>,
			},
			toiletsTable: {
				headers: [
					{ label: "トイレ名称", field: "name", width: "16%" },
					{ label: "紙", field: "toiletName", width: "12%" },
					{ label: "水石鹸", field: "facilityType", width: "12%" },
					{ label: "アラート", field: "facilityStatus", width: "60%" },
				],
				rows: [] as Array<ToiletCol>,
			},
		}
	},
	methods: {
		async fetchDbTimeLatest(): Promise<void> {
			this.state.dbTimeLatest.isFetching = true
			/* Fetch GetTimeLatest API */
			const query = await this.$http.get("/stations/lastupdated")
			// success
			if (query.status === 200) {
				this.latestTime = query.data.latestTime
				this.state.dbTimeLatest.isFetching = false
				if (!this.state.dbTimeLatest.isFetched) {
					this.state.dbTimeLatest.isFetched = true
				}
			}
		},
		async getStationUsage(): Promise<void> {
			const id = this.$route.params.id
			if (id) {
				this.state.station.isFetching = true
				const query = await this.$http.get("/client/stations/usage/" + id)
				if (query.status !== 200) {
					return
				}
				this.$breadCrumb.station_id = query.data.stationId
				this.$breadCrumb.station_name = query.data.stationName || ""
				this.stationName = query.data.stationName || ""
				this.toiletsTable.rows = query.data.toilets
				this.withSortCubiclesTable.rows = query.data.cubicles
				this.toilets = query.data.toilets
				this.cubicles = query.data.cubicles
				this.state.station.isFetching = false
				if (!this.state.station.isFetched) {
					this.state.station.isFetched = true
				}
				if (query.data.isDisplay) {
					// アラートがあったらalertModalを表示する
					const stationAlert: stationAlert = {
						stationName: query.data.stationName,
						alerts: [],
					}
					if (!query.data.cubicles || !query.data.cubicles.length) {
						return
					}
					query.data.cubicles.map((cubicle: any) => {
						if (!cubicle.alertArray || !cubicle.alertArray.length) {
							return
						}
						const alerts = [] as any
						cubicle.alertArray.map((alert: any) => {
							switch (alert.alert) {
								case "CLEANING_REQUIRED":
									if (
										!!query.data.alertNotification.CLEANING_REQUIRED &&
										query.data.alertNotification.CLEANING_REQUIRED !== 0
									) {
										alerts.push(alert.alert)
									}
									break
								case "CONTAMINATED":
									if (
										!!query.data.alertNotification.CONTAMINATED &&
										query.data.alertNotification.CONTAMINATED !== 0
									) {
										alerts.push(alert.alert)
									}
									break
								case "LONG_STAY":
									if (
										!!query.data.alertNotification.LONG_STAY &&
										query.data.alertNotification.LONG_STAY !== 0
									) {
										alerts.push(alert.alert)
									}
									break
								case "SENSOR_UNRESPONSIVE":
									if (
										!!query.data.alertNotification.SENSOR_UNRESPONSIVE &&
										query.data.alertNotification.SENSOR_UNRESPONSIVE !== 0
									) {
										alerts.push(alert.alert)
									}
									break
								case "DAMAGE_STOLEN":
									if (
										!!query.data.alertNotification.PAPER_STOLEN &&
										query.data.alertNotification.PAPER_STOLEN !== 0 &&
										cubicle.facilityType === 1
									) {
										alerts.push("PAPER_STOLEN")
									}
									if (
										!!query.data.alertNotification.WATER_STOLEN &&
										query.data.alertNotification.WATER_STOLEN !== 0 &&
										cubicle.facilityType !== 1
									) {
										alerts.push("WATER_STOLEN")
									}
									break
								case "OVERFLOW":
									if (
										!!query.data.alertNotification.OVERFLOW &&
										query.data.alertNotification.OVERFLOW !== 0
									) {
										alerts.push(alert.alert)
									}
									break
								case "FALL_DOWN":
									if (
										!!query.data.alertNotification.FALL_DOWN &&
										query.data.alertNotification.FALL_DOWN !== 0
									) {
										alerts.push(alert.alert)
									}
									break
							}
						})
						alerts.forEach((alert: string) => {
							if (stationAlert.alerts.includes(alert)) {
								return
							}
							stationAlert.alerts.push(alert)
						})
					})

					// モーダルに表示するアラートをソートする
					stationAlert.alerts = sortAlerts(stationAlert.alerts)

					// ignoreAlertListにあるアラートを除外する
					if (stationAlert.alerts.length) {
						const ignoreAlertList = this.$alertModal.ignoreAlertList
						const alerts = stationAlert.alerts.filter((alertName) => {
							return ignoreAlertList.every((ignoreAlert) => {
								return alertName !== ignoreAlert.alertName
							})
						})

						this.$alertModal.ignoreAlertList =
							this.$alertModal.ignoreAlertList.concat(
								alerts.map((alert) => {
									return {
										stationName: query.data.stationName,
										alertName: alert,
									}
								})
							)

						// アラートモーダルに表示するアラートがあった場合
						if (alerts.length !== 0) {
							let repeatType = 0
							alerts.map((alert) => {
								switch (query.data.alertNotification[alert]) {
									case 1:
										repeatType = 1
										break
									case 2:
										if (repeatType !== 1) {
											repeatType = 2
										}
										break
									default:
										break
								}
							})

							this.$alertModal.display = true
							const isPaper = alerts.includes("PAPER_STOLEN")
							const isWater = alerts.includes("WATER_STOLEN")
							if (isPaper && isWater) {
								alerts.splice(alerts.indexOf("PAPER_STOLEN"), 1)
							}
							this.$alertModal.alerts = [
								{
									stationName: query.data.stationName,
									alerts,
								},
							]
							this.$alertModal.repeatType = repeatType
							if (repeatType !== 0) {
								this.$alertModal.isPlay = true
							}
						}
					}
				}

				// アラートがなくなったら、ignoreAlertListからなくなったアラートを削除する
				const alertAll: IgnoreAlert[] = []
				if (!query.data.cubicles || !query.data.cubicles.length) {
					return
				}
				query.data.cubicles.map((cubicle: any) => {
					if (!cubicle.alertArray || !cubicle.alertArray.length) {
						return
					}
					cubicle.alertArray.map((alert: any) => {
						let currentAlert
						if (alert.alert === "DAMAGE_STOLEN") {
							if (cubicle.facilityType === 1) {
								currentAlert = {
									stationName: query.data.stationName,
									alertName: "PAPER_STOLEN",
								}
							} else {
								currentAlert = {
									stationName: query.data.stationName,
									alertName: "WATER_STOLEN",
								}
							}
						} else {
							currentAlert = {
								stationName: query.data.stationName,
								alertName: alert.alert,
							}
						}
						if (alertAll.includes(currentAlert)) {
							return
						}
						alertAll.push(currentAlert)
					})
				})
				this.$alertModal.ignoreAlertList =
					this.$alertModal.ignoreAlertList.filter((ignoreAlert) => {
						return alertAll.some((alert) => {
							return (
								alert.stationName === ignoreAlert.stationName &&
								alert.alertName === ignoreAlert.alertName
							)
						})
					})
			}
		},
		async getStationGraphChart(): Promise<void> {
			const id = this.$route.params.id
			if (id) {
				this.state.graph.isFetching = true
				const query = await this.$http.get("/client/stations/graph/" + id)
				if (query.status === 200) {
					this.graphData = query.data
					this.state.graph.isFetching = false
					if (!this.state.graph.isFetched) {
						this.state.graph.isFetched = true
					}
				}
			}
		},
		async postCleanToiletData(
			sensorId: number,
			cleanType: string,
			isDevice: boolean
		) {
			if (!this.state.cleaningData.isFetching) {
				const user_id = this.$currentUser.user_id
				if (user_id) {
					try {
						this.state.cleaningData.isFetching = true
						const query = await this.$http.post(
							"/toilets/cleaning/" + sensorId + "/" + cleanType,
							{
								user: user_id,
								isDevice: isDevice,
							}
						)
						if (query.status === 200) {
							this.$notification.display = true
							this.$notification.type = "actionSuccess"
							if (cleanType === "start") {
								this.$notification.message = "開始指示をしました"
							} else {
								this.$notification.message = "完了登録をしました"
							}
							// rowsを更新する
							await this.fetchDbTimeLatest()
							await this.getStationUsage()
							await this.getStationGraphChart()
							// トイレ別、facilityId 昇順にソートする
							this.sortRows(this.state.station.sortType)
						}
					} catch (error: any) {
						if (error.response.status !== 0) {
							this.$notification.display = true
							this.$notification.type = "error"
							this.$notification.message =
								error.response.status + ":" + error.response.data.error
						} else {
							this.$notification.display = true
							this.$notification.type = "error"
							this.$notification.message =
								"システムエラーが発生しました。しばらくしてから再度実行してください。"
						}
					}
				}
				// state.cleaningData.isFetchingを更新し、清掃指示ボタンを押下できるようにする
				this.state.cleaningData.isFetching = false
			}
		},
		async isAllowStation(): Promise<boolean> {
			const id = this.$route.params.id
			if (id) {
				const query = await this.$http.get("/client/stations/affiliation/" + id)
				if (query.status === 200) {
					return query.data.is
				} else {
					return false
				}
			} else {
				return false
			}
		},
		selectCubicles() {
			this.isCubicles = true
		},
		selectToilets() {
			this.isCubicles = false
		},
		sortRowsDefault() {
			// toiletMapIconId昇順でソート
			let sortRows = this.withSortCubiclesTable.rows
			return sortRows.sort((a, b) => {
				return a.toiletMapIconId < b.toiletMapIconId ? -1 : 1
			})
		},
		sortRows(sortType: number) {
			// this.withSortCubiclesTable.rowsがない場合にソートを行うとエラーが起こるためreturnする
			if (!this.withSortCubiclesTable.rows) {
				return
			}

			// state.station.sortTypeを更新
			this.state.station.sortType = sortType

			// rowsの並びを初期化
			let sortCubicleRows = this.sortRowsDefault()

			// sortTypeごとに処理を切り分け
			switch (sortType) {
				case 0:
					break
				case 1:
					// 使用状況でソート
					// 使用状況が満同士、使用分数があるの場合は使用時間降順に並べる
					sortCubicleRows.sort((a, b) => {
						return a.facilityStatus === "occupied" &&
							b.facilityStatus === "occupied" &&
							!!a.usageMinute &&
							!!b.usageMinute
							? Number(a.usageMinute) - Number(b.usageMinute)
							: a.facilityStatus === "occupied" && b.facilityStatus === "vacant"
							? 1
							: -1
					})
					// 個室を先頭に表示し、それ以外をfacilityTypeでソート
					sortCubicleRows.sort((a, b) => {
						return a.facilityType === 1 ? -1 : a.facilityType - b.facilityType
					})
					break
				case 2:
					// アラート(refill or warning)なら先頭に表示
					sortCubicleRows.sort((a) => {
						return (a.facilityType === 1 &&
							(a.paperStatus === "refill" || a.paperStatus === "warning")) ||
							((a.facilityType === 2 || a.facilityType === 6) &&
								(a.soapStatus === "refill" || a.soapStatus === "warning"))
							? 1
							: -1
					})
					// アラートが上がっている行同士でstockCountを比較
					sortCubicleRows.sort((a, b) => {
						return (a.facilityType === 1 &&
							(a.paperStatus === "refill" || a.paperStatus === "warning") &&
							b.facilityType === 1 &&
							(b.paperStatus === "refill" || b.paperStatus === "warning")) ||
							(a.facilityType === 1 &&
								(a.paperStatus === "refill" || a.paperStatus === "warning") &&
								(b.facilityType === 2 || b.facilityType === 6) &&
								(b.soapStatus === "refill" || b.soapStatus === "warning")) ||
							((a.facilityType === 2 || a.facilityType === 6) &&
								(a.soapStatus === "refill" || a.soapStatus === "warning") &&
								b.facilityType === 1 &&
								(b.paperStatus === "refill" || b.paperStatus === "warning")) ||
							((a.facilityType === 2 || a.facilityType === 6) &&
								(a.soapStatus === "refill" || a.soapStatus === "warning") &&
								(b.facilityType === 2 || b.facilityType === 6) &&
								(b.soapStatus === "refill" || b.soapStatus === "warning"))
							? b.stockCount - a.stockCount
							: 0
					})
					// facilityTypeが5なら末尾に表示
					sortCubicleRows.sort((a) => {
						return a.facilityType === 5 ? 1 : -1
					})
					break
				case 3:
					{
						// アラート発生時間でソート
						// アラートで最も古い時間をrows.timeLatestに格納
						// rowごとにtimeLatestでソート
						let NewArray = this.withSortCubiclesTable.rows
						for (let i = 0; i < NewArray.length; i++) {
							const sortedAlertTime =
								NewArray[i].alertArray !== undefined
									? NewArray[i].alertArray
									: []
							if (sortedAlertTime.length > 0) {
								const newAlert = sortedAlertTime.sort((a, b) => {
									return a.time < b.time ? -1 : 1
								})
								NewArray[i].timeLatest = newAlert[0].time
							} else {
								NewArray[i].timeLatest = ""
							}
						}
						sortCubicleRows = NewArray.sort((a, b) => {
							return (
								(a.timeLatest === "" ? 1 : 0) - (b.timeLatest === "" ? 1 : 0) ||
								+(a.timeLatest > b.timeLatest) ||
								-(a.timeLatest < b.timeLatest)
							)
						})
					}
					break
				default:
					break
			}
			this.cubiclesTable.rows = sortCubicleRows
		},
		changeGraphType(graphType: number) {
			this.state.graph.graphType = graphType
		},
		async onToiletNameClick(
			stationId: number,
			toiletId: number
		): Promise<void> {
			if (stationId) {
				const query = await this.$http.get(
					"/client/stations/affiliation/" + stationId
				)
				if (query.status === 200 && query.data.is === true) {
					this.$router.push({
						name: "toilet-details-view",
						params: { id: stationId, toiletId: toiletId },
					})
				}
			}
		},
		async onRowClick(stationId: number, toiletId: number): Promise<void> {
			if (stationId) {
				const query = await this.$http.get(
					"/client/stations/affiliation/" + stationId
				)
				if (query.status === 200 && query.data.is === true) {
					this.$router.push({
						name: "toilet-details-view",
						params: { id: stationId, toiletId: toiletId },
					})
				}
			}
		},
	},
	async mounted() {
		this.updateTimer = setInterval(async () => {
			await this.fetchDbTimeLatest()
			await this.getStationUsage()
			await this.getStationGraphChart()
			// 並び順に並べる
			this.sortRows(this.state.station.sortType)
		}, 30000)
	},
	unmounted() {
		clearInterval(this.updateTimer)
	},
})
