import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_door_icon = _resolveComponent("door-icon")!
  const _component_wash_hand_icon = _resolveComponent("wash-hand-icon")!
  const _component_small_icon = _resolveComponent("small-icon")!

  return (_ctx.toiletType === 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_door_icon)
      ]))
    : (_ctx.toiletType === 2 || _ctx.toiletType === 6)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_wash_hand_icon)
        ]))
      : (_ctx.toiletType === 5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_small_icon)
          ]))
        : _createCommentVNode("", true)
}