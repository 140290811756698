import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a05e5e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }
const _hoisted_2 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_circle_icon = _resolveComponent("check-circle-icon")!
  const _component_warning_icon = _resolveComponent("warning-icon")!
  const _component_close_icon = _resolveComponent("close-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
			_ctx.$notification.type === 'error' ? 'error notification' : 'notification'
		)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$notification.type === 'actionSuccess')
        ? (_openBlock(), _createBlock(_component_check_circle_icon, {
            key: 0,
            style: {"margin-right":"8px"},
            fill: "#ffffff"
          }))
        : (_ctx.$notification.type === 'error')
          ? (_openBlock(), _createBlock(_component_warning_icon, {
              key: 1,
              style: {"margin-right":"8px"},
              fill: "#ffffff",
              width: "24",
              height: "24"
            }))
          : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$notification.message), 1)
    ]),
    _createElementVNode("div", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$notification.toggleDisplay()))
    }, [
      _createVNode(_component_close_icon, {
        fill: "#ffffff",
        class: "v-icon"
      })
    ])
  ], 2))
}