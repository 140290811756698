import * as yup from "yup"

// required
export function required(name: string) {
	return yup.string().required(`${name} を入力してください`)
}

// email
export function email() {
	return yup
		.string()
		.required("メールアドレス を入力してください")
		.email("メールアドレス形式ではありません")
}

// password
export function password() {
	return yup
		.string()
		.min(8, "パスワード は8文字以上入力してください")
		.max(255, "パスワード は255文字までしか入力できません")
		.required("パスワード を入力してください")
		.matches(
			/^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
			"大・小文字含む半角英数8文字以上で入力してください"
		)
}
