
import { defineComponent } from "vue"
import {
	vTitle,
	vTable,
	vIsControlled,
	vPaper,
	vSoap,
	vAlert,
	vDbTimeLatest,
} from "@/utils/components"
import { ProgressCircleIcon } from "@/utils/icons"
import { stationAlert } from "@/components/types/stationAlert"
import { IgnoreAlert } from "@/store/alertModal"
import { sortAlerts } from "@/utils/sortAlerts"

export default defineComponent({
	name: "StationsIndex.vue",
	components: {
		vTitle,
		vTable,
		vIsControlled,
		vPaper,
		vSoap,
		vAlert,
		vDbTimeLatest,
		ProgressCircleIcon,
	},
	async created() {
		/** notificationの初期化 */
		this.$notification.display = false
		this.$notification.type = "actionSuccess"
		this.$notification.message = ""

		/** breadCrumbの初期化 */
		this.$breadCrumb.page = "stations"
		this.$breadCrumb.station_id = null
		this.$breadCrumb.station_name = ""
		this.$breadCrumb.toilet_id = null
		this.$breadCrumb.toilet_name = ""

		/** alertModalの初期化 */
		this.$alertModal.display = false
		this.$alertModal.alerts = null
		this.$alertModal.repeatType = 0
		this.$alertModal.isPlay = false

		await this.fetchStations()
		await this.fetchDbTimeLatest()
	},
	data() {
		return {
			title: "駅を選択してください",
			latestTime: 0,
			table: {
				headers: [
					{ label: "線区", field: "lineName", width: "8%" },
					{ label: "管理駅", field: "isControlled", width: "7%" },
					{ label: "駅名", field: "stationName", width: "7%" },
					{ label: "紙", field: "paper", width: "8%" },
					{ label: "水石鹸", field: "soap", width: "8%" },
					{ label: "アラート", field: "alert", width: "67%" },
				],
				rows: [],
				hidden: [
					"stationId",
					"paperStockCount",
					"paperCapacityCount",
					"soapStockCount",
					"soapCapacityCount",
				],
			},
			updateTimer: 0,
			state: {
				dbTimeLatest: {
					isFetching: false,
					isFetched: false,
				},
				stations: {
					isFetching: false,
					isFetched: false,
				},
			},
			// modal用
			open: true,
		}
	},

	methods: {
		async fetchStations(): Promise<void> {
			this.state.stations.isFetching = true

			/* Fetch GetStations API */
			const query = await this.$http.get("/client/stations/")
			// success
			if (query.status !== 200) {
				return
			}
			// if (query.data.isDisplay) {
			// アラートがあったらalertModalを表示する
			const stationAlert: stationAlert = {
				stationName: query.data.stationName,
				alerts: [],
			}
			if (!query.data.stations || !query.data.stations.length) {
				return
			}
			query.data.stations.map((station: any) => {
				if (!station.cubicles || !station.cubicles.length) {
					return
				}
				station.cubicles.map((cubicle: any) => {
					if (!cubicle.alertArray || !cubicle.alertArray.length) {
						return
					}
					const alerts = [] as any
					cubicle.alertArray.map((alert: any) => {
						switch (alert.alert) {
							case "CLEANING_REQUIRED":
								if (
									!!station.alertNotification.CLEANING_REQUIRED &&
									station.alertNotification.CLEANING_REQUIRED !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "CONTAMINATED":
								if (
									!!station.alertNotification.CONTAMINATED &&
									station.alertNotification.CONTAMINATED !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "LONG_STAY":
								if (
									!!station.alertNotification.LONG_STAY &&
									station.alertNotification.LONG_STAY !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "SENSOR_UNRESPONSIVE":
								if (
									!!station.alertNotification.SENSOR_UNRESPONSIVE &&
									station.alertNotification.SENSOR_UNRESPONSIVE !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "DAMAGE_STOLEN":
								if (
									!!station.alertNotification.PAPER_STOLEN &&
									station.alertNotification.PAPER_STOLEN !== 0 &&
									cubicle.facilityType === 1
								) {
									alerts.push("PAPER_STOLEN")
								}
								if (
									!!station.alertNotification.WATER_STOLEN &&
									station.alertNotification.WATER_STOLEN !== 0 &&
									cubicle.facilityType !== 1
								) {
									alerts.push("WATER_STOLEN")
								}
								break
							case "OVERFLOW":
								if (
									!!station.alertNotification.OVERFLOW &&
									station.alertNotification.OVERFLOW !== 0
								) {
									alerts.push(alert.alert)
								}
								break
							case "FALL_DOWN":
								if (
									!!cubicle.alertNotification.FALL_DOWN &&
									cubicle.alertNotification.FALL_DOWN !== 0
								) {
									alerts.push(alert.alert)
								}
								break
						}
					})
					alerts.forEach((alert: string) => {
						if (stationAlert.alerts.includes(alert)) {
							return
						}
						stationAlert.alerts.push(alert)
					})
				})

				// モーダルに表示するアラートをソートする
				stationAlert.alerts = sortAlerts(stationAlert.alerts)

				// ignoreAlertListにあるアラートを除外する
				if (stationAlert.alerts.length) {
					const ignoreAlertList = this.$alertModal.ignoreAlertList
					const alerts = stationAlert.alerts.filter((alertName) => {
						return ignoreAlertList.every((ignoreAlert) => {
							return alertName !== ignoreAlert.alertName
						})
					})

					this.$alertModal.ignoreAlertList =
						this.$alertModal.ignoreAlertList.concat(
							alerts.map((alert) => {
								return {
									stationName: station.stationName,
									alertName: alert,
								}
							})
						)
					// アラートモーダルに表示するアラートがあった場合
					if (alerts.length !== 0) {
						let repeatType = 0
						alerts.map((alert) => {
							switch (station.alertNotification[alert]) {
								case 1:
									repeatType = 1
									break
								case 2:
									if (repeatType !== 1) {
										repeatType = 2
									}
									break
								default:
									break
							}
						})
						this.$alertModal.display = true
						// 紙と水石鹸の重複を省く
						const isPaper = alerts.includes("PAPER_STOLEN")
						const isWater = alerts.includes("WATER_STOLEN")
						if (isPaper && isWater) {
							alerts.splice(alerts.indexOf("PAPER_STOLEN"), 1)
						}
						this.$alertModal.alerts = [
							{
								stationName: station.stationName,
								alerts,
							},
						]
						this.$alertModal.repeatType = repeatType
						if (repeatType !== 0) {
							this.$alertModal.isPlay = true
						}
					}
				}
			})
			// }

			// アラートがなくなったら、ignoreAlertListからなくなったアラートを削除する
			const alertAll: IgnoreAlert[] = []
			query.data.stations.map((station: any) => {
				if (!station.cubicles || !station.cubicles.length) {
					return
				}
				station.cubicles.map((cubicle: any) => {
					if (!cubicle.alertArray || !cubicle.alertArray.length) {
						return
					}
					cubicle.alertArray.map((alert: any) => {
						let currentAlert
						if (alert.alert === "DAMAGE_STOLEN") {
							if (cubicle.facilityType === 1) {
								currentAlert = {
									stationName: station.stationName,
									alertName: "PAPER_STOLEN",
								}
							} else {
								currentAlert = {
									stationName: station.stationName,
									alertName: "WATER_STOLEN",
								}
							}
						} else {
							currentAlert = {
								stationName: station.stationName,
								alertName: alert.alert,
							}
						}
						if (alertAll.includes(currentAlert)) {
							return
						}
						alertAll.push(currentAlert)
					})
				})
			})

			this.$alertModal.ignoreAlertList =
				this.$alertModal.ignoreAlertList.filter((ignoreAlert) => {
					return alertAll.some((alert) => {
						return (
							alert.stationName === ignoreAlert.stationName &&
							alert.alertName === ignoreAlert.alertName
						)
					})
				})

			this.table.rows = query.data.usage
			this.sortedRowsByStationId()
			this.state.stations.isFetching = false
			if (!this.state.stations.isFetched) {
				this.state.stations.isFetched = true
			}
		},
		async fetchDbTimeLatest(): Promise<void> {
			this.state.dbTimeLatest.isFetching = true

			/* Fetch GetTimeLatest API */
			const query = await this.$http.get("/stations/lastupdated")
			// success
			if (query.status === 200) {
				this.latestTime = query.data.latestTime
				this.state.dbTimeLatest.isFetching = false
				if (!this.state.dbTimeLatest.isFetched) {
					this.state.dbTimeLatest.isFetched = true
				}
			}
		},
		sortedRowsByStationId() {
			return this.table.rows.sort((a: any, b: any) => {
				return b.stationId - a.stationId
			})
		},
		/**
		 * onRowClick
		 * @param row
		 */
		async onRowClick(row: { stationId: string }): Promise<void> {
			const id = row.stationId
			if (id) {
				const query = await this.$http.get("/client/stations/affiliation/" + id)
				if (query.status === 200 && query.data.is === true) {
					this.$router.push({
						name: "station-view",
						params: { id: row.stationId },
					})
				}
			}
		},
	},
	async mounted() {
		this.updateTimer = setInterval(async () => {
			await this.fetchStations()
			await this.fetchDbTimeLatest()
		}, 30000)
	},
	unmounted() {
		clearInterval(this.updateTimer)
	},
})
