
import { defineComponent } from "vue"
import { RemoveIcon, CircleIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentIsControlled",
	components: {
		RemoveIcon,
		CircleIcon,
	},
	props: {
		isControlled: {
			type: Boolean,
			default: false,
		},
	},
})
