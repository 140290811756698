
import { defineComponent } from "vue"

export default defineComponent({
	name: "ProgressCircleIcon",
	props: {
		width: {
			type: Number,
			default: 20,
		},

		height: {
			type: Number,
			default: 20,
		},
	},
})
