import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_icon = _resolveComponent("circle-icon")!
  const _component_remove_icon = _resolveComponent("remove-icon")!

  return (_ctx.isControlled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_circle_icon)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_remove_icon)
      ]))
}