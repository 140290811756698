
import { defineComponent } from "vue"
import { Series } from "@/utils/toiletSeries"
import { Chart, registerables } from "chart.js"
import { Bar } from "vue-chartjs"
import zoomPlugin from "chartjs-plugin-zoom"

Chart.register(...registerables)
Chart.register(zoomPlugin)

export default defineComponent({
	name: "ChartJs",
	components: {
		Bar,
	},
	props: {
		graphData: {
			type: Object,
			require: true,
		},
	},
	data() {
		let SeriesObj = [] as any
		let data

		SeriesObj = Series(this.graphData)
		const options = {
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				mode: "index",
				intersect: false,
			},
			stacked: false,
			plugins: {
				title: {
					display: false,
				},
				legend: {
					position: "bottom",
				},
				tooltip: {
					enabled: false,
				},
				zoom: {
					pan: {
						enabled: true,
						mode: "x",
						modifierKey: "shift",
						threshold: 20,
					},
					zoom: {
						wheel: {
							enabled: true,
							speed: 0.001,
						},
						drag: {
							enabled: true,
							backgroundColor: "rgba(225,225,225,0.5)",
						},
						pinch: {
							enabled: true,
						},

						mode: "x",
					},
				},
			},
			scales: {
				x: {
					border: {
						display: false,
					},
					grid: {
						display: false,
						drawOnChartArea: false,
						drawTicks: true,
					},
				},
				y: {
					display: true,
					suggestedMin: 0,
					suggestedMax: SeriesObj.yaxis[0].max,
					beginAtZero: true,
					position: "left",
					title: {
						display: true,
						text: "使用回数",
					},
					grid: {
						display: false,
					},
				},
				y1: {
					type: "linear",
					display: true,
					suggestedMin: 0,
					suggestedMax: 100,
					beginAtZero: true,
					position: "right",
					title: {
						display: true,
						text: "使用率(%)",
					},
				},
			},
		}
		data = {
			labels: [
				"04:00",
				"05:00",
				"06:00",
				"07:00",
				"08:00",
				"09:00",
				"10:00",
				"11:00",
				"12:00",
				"13:00",
				"14:00",
				"15:00",
				"16:00",
				"17:00",
				"18:00",
				"19:00",
				"20:00",
				"21:00",
				"22:00",
				"23:00",
				"00:00",
				"01:00",
				"02:00",
			],
			datasets: [] as any,
		}
		for (let i = 0; i < SeriesObj.series.length; i++) {
			let name
			let array = [] as any
			let bgcolor
			let isColumn = false
			let obj
			for (let j = 0; j < SeriesObj.series[i].data.length; j++) {
				if (SeriesObj.series[i].type === "column") {
					name = SeriesObj.series[i].name
					array[j] = SeriesObj.series[i].data[j]
					bgcolor = SeriesObj.colors[i]
					isColumn = true
				} else {
					name = SeriesObj.series[i].name
					array[j] = SeriesObj.series[i].data[j]
					bgcolor = SeriesObj.colors[i]
				}
			}
			if (isColumn) {
				obj = {
					label: name,
					data: array,
					backgroundColor: bgcolor,
					yAxisID: "y",
				}
			} else {
				obj = {
					type: "line",
					label: name,
					data: array,
					borderColor: bgcolor,
					backgroundColor: bgcolor,
					yAxisID: "y1",
				}
			}
			data.datasets.push(obj)
		}
		return {
			data,
			options,
		}
	},
})
