
import { defineComponent } from "vue"
import { RemoveIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentUsageStatus",
	components: {
		RemoveIcon,
	},
	props: {
		status: {
			type: String,
			default: "",
		},
		minutes: {
			type: Number,
			default: 0,
		},
		facilityType: {
			type: Number,
			default: 1,
		},
	},
})
