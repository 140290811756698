
// @ts-check
import { defineComponent } from "vue"
import { ChevronRightIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentBreadCrumbs",
	components: {
		ChevronRightIcon,
	},
	methods: {
		goToStations() {
			this.$router.push({ name: "stations" })
		},
		goToStation(stationId: number) {
			this.$router.push({
				name: "station-view",
				params: { id: stationId },
			})
		},
		goToToilet(toiletFacilityId: number) {
			this.$router.push({
				name: "toilet-details-view",
				params: { toiletFacilityId: toiletFacilityId },
			})
		},
	},
})
