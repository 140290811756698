/* eslint-disable prettier/prettier */
import { defineStore } from "pinia"

// パンくずリストストアインターフェース
export interface BreadCrumbStoreType {
	// どの画面にいるか
	page: | string
	// 駅名
	station_name: string | null
	// 駅ID
	station_id: number | null
	// トイレ名
	toilet_name: string | null
	// トイレID toiletFacilityId
	toilet_id: number | null
}

/**
 * パンくずリストストア
 */
export const BreadCrumbStore = defineStore("breadCrumb", {
	state: () =>
	({
		page: "stations",
		station_name: "",
		station_id: null,
		toilet_name: "",
		toilet_id: null,
	} as BreadCrumbStoreType),
})
