import { createApp } from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import router from "./router"
import defaultMixin from "@/mixins/default"
import http from "@/utils/http"
import { AxiosInstance } from "axios"
import { UserStore } from "@/store/user"
import { BreadCrumbStore } from "@/store/breadCrumb"
import { NotificationStore } from "@/store/notification"
import { AlertModalStore } from "@/store/alertModal"
import "./index.css"
import * as DateFilter from "./utils/filter/date-filter"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

const pinia = createPinia()

// create vue app
const app = createApp(App)
app.use(pinia)
app.use(router)
app.mixin(defaultMixin)
app.mount("#app")


// globalProperties
// // http
app.config.globalProperties.$http = http

// // filters
const filters = {
	...DateFilter,
}
app.config.globalProperties.$filter = filters

// // store
// ユーザーデータストア
const userStore = UserStore()
app.config.globalProperties.$currentUser = userStore
// パンくずリスト
const breadCrumbStore = BreadCrumbStore()
app.config.globalProperties.$breadCrumb = breadCrumbStore
// 通知ストア
const notificationStore = NotificationStore()
app.config.globalProperties.$notification = notificationStore
// アラートモーダルストア
const alertModalStore = AlertModalStore()
app.config.globalProperties.$alertModal = alertModalStore

// sentry
// @see https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
Sentry.init({
	app,
	dsn: process.env.SENTRY_DSN,
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: [
				"https://cleaning-cooperative.development.vacanservice.com/",
				"https://cleaning-cooperative.staging.vacanservice.com/",
				"https://cleaning-cooperative.vacanservice.com/",
			],
		}),
	],
	tracesSampleRate: 1.0,
})


// ts
declare module "@vue/runtime-core" {
	interface ComponentCustomProperties {
		$http: AxiosInstance
		$currentUser: typeof userStore
		$filter: typeof filters
		$breadCrumb: typeof breadCrumbStore
		$notification: typeof notificationStore
		$alertModal: typeof alertModalStore

		// defaultMixin
		eventTargetValue(event: Event): string
	}
}
