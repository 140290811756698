import { nextTick } from "vue"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { AxiosResponse } from "axios"
import loginRouter from "@/router/login"
import stationsRouter from "@/router/stations"
import toiletDetailsRouter from "@/router/toilet-details"
import http from "@/utils/http"
import cookies from "@/utils/cookies"
import NotFoundView from "@/views/NotFoundView.vue"
import { UserStore } from "@/store/user"

const routes: Array<RouteRecordRaw> = [
	// redirect login page
	{
		path: "/",
		redirect: "/login",
	},
	// pages
	...loginRouter,
	...stationsRouter,
	...toiletDetailsRouter,
	// handle Not Found Pages
	{
		path: "/:catchAll(.*)",
		component: NotFoundView,
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

// middleware
let isTokenValidated = false
export function logoutRouter() {
	isTokenValidated = false
}
let result: AxiosResponse | { status: undefined } = {
	status: undefined,
}

// 画面遷移時
router.beforeEach(async (to, from, next) => {
	// @TODO check authenticated user

	// Change Document Title
	nextTick(() => {
		document.title = process.env.VUE_APP_TITLE
	})

	const vn_refresh_token = cookies.get("vn_access_token")
	const isLogined = Boolean(vn_refresh_token)

	if (isLogined) {
		/* Validate Token Once */
		if (!isTokenValidated) {
			try {
				// 最新のアクセストークンを取得
				result = await http
					.post("auth/token/refresh", {
						refresh_token: cookies.get("vn_refresh_token"),
					})
					.then((response) => {
						const data = response.data
						cookies.set("vn_access_token", data.access_token, 1)
						return response
					})
				isTokenValidated = result.status === 200

				// ユーザー情報をstoreに保存
				if (isTokenValidated) {
					const user = await http.get("profile")
					const currentUser = UserStore()
					currentUser.user_id = user.data.user_id
					currentUser.email = user.data.email
				}
			} catch {
				// Catch 500 internal error
				cookies.del("vn_access_token")
				cookies.del("vn_refresh_token")
				next({ name: "login-index" })
			}
		}

		if (isTokenValidated) {
			/**
			 * if User still logged in redirect to the application
			 * else do logout and redirect to login page
			 * */
			const loggedInRoute = ["home", "login-index"]
			if (to.name)
				loggedInRoute.includes(String(to.name)) && next({ name: "stations" })
		}
	}

	// handle protected pages
	// @TODO - Check Expiration and Redirection upon Refresh

	if (to.matched.some((route) => route.meta.protected) && !isLogined) {
		return next({ path: "/login" })
	} else return next()
})

export default router
