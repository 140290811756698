/* eslint-disable prettier/prettier */
import { defineStore } from "pinia"

// ユーザーストアインターフェース
export interface UserStoreType {
	// ユーザーID
	user_id: number | null
	// email
	email: string | null
}

/**
 * ユーザーストア
 */
export const UserStore = defineStore("user", {
	state: () =>
	({
		user_id: null,
		email: "",
	} as UserStoreType),
	actions: {
		reset() {
			this.user_id = null
			this.email = ""
		}
	}
})
