
import { defineComponent } from "vue"
import { vNotification } from "@/utils/components"

export default defineComponent({
	name: "login-layout",
	components: {
		vNotification,
	},
})
