
import { defineComponent } from "vue"
import { RemoveIcon, WarningPaperIcon, RefillPaperIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentPaper",
	components: {
		RemoveIcon,
		WarningPaperIcon,
		RefillPaperIcon,
	},
	props: {
		paper: {
			type: String,
			default: "normal",
		},
		paperStockCount: {
			type: Number,
			default: 0,
		},
		paperCapacityCount: {
			type: Number,
			default: 0,
		},
	},
})
