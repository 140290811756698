
// @ts-check
import { defineComponent } from "vue"
import { ProgressCircleIcon } from "@/utils/icons"

export default defineComponent({
	components: { ProgressCircleIcon },
	name: "ComponentDbTimeLatest",
	props: {
		latestTime: {
			type: Number,
			require: false,
		},
		isFetching: {
			type: Boolean,
			require: true,
			default: false,
		},
		isFetched: {
			type: Boolean,
			require: true,
			default: false,
		},
	},
})
