
import { defineComponent } from "vue"

export default defineComponent({
	name: "CloseCircleIcon",
	props: {
		fill: {
			type: String,
			default: "#4E4E4E",
		},

		width: {
			type: String,
			default: "24",
		},

		height: {
			type: String,
			default: "24",
		},
	},
})
