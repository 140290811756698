
import { defineComponent } from "vue"

export default defineComponent({
	name: "ComponentButton",
	props: {
		label: String,
		customStyle: {
			type: Object,
		},
		type: {
			type: String,
			default: "button",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		fontSize: {
			type: String,
			default: "14px",
		},
		customClass: String,
		width: String,
		height: String,
		outline: String,
		icon: String,

		borderRadius: {
			type: String,
			default: "100px",
		},

		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		style() {
			return {
				width: this.width,
				height: this.height,
				fontSize: this.fontSize,
				borderRadius: this.borderRadius,
				...this.customStyle,
			}
		},
		wrapType(): "button" | "submit" {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return this.type as any
		},
	},
})
