import _ from 'lodash'
import { ToiletAvailable } from '@/components/types/toilet'

export const Series = (graphData: any) => {
	const ToiletAvailable: ToiletAvailable[] = []
	let SeriesArray = []
	let ColumnSeries: any = []
	let LineSeries: any = []
	const SeriesArrayHorizontal = []
	let maxColumn = 1

	// graphDataから表示するトイレを抽出する
	Object.keys(graphData).forEach((key) => {
		Object.keys(graphData[key]).forEach((xkey) => {
			graphData[key][xkey].forEach((item: any) => {
				if (!ToiletAvailable.find((x: any) => x.name === item.cubicleName)) {
					ToiletAvailable.push({ id: xkey, name: item.cubicleName })
				}
			})
		})
	})

	// 個室(vCore)、大便器、小便器、手洗い場(LIXIL)ごとに棒グラフデータを作成する
	ToiletAvailable.forEach((x: ToiletAvailable) => {
		const dataSeries = []
		// 04~23,00,01,02を作成
		for (let i = 4; i <= 26; i++) {
			const m = i === 24 ? 0 : i === 25 ? 1 : i === 26 ? 2 : i
			const seriesNumber = m.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false,
			})
			// graphDataを04~02時間ごとに調べ、データがあったらグラフデータを作成する
			if (graphData[seriesNumber] !== undefined) {
				if (graphData[seriesNumber][x.id] !== undefined) {
					if (graphData[seriesNumber][x.id][0]?.useCount !== undefined) {
						const useCountUsage = parseInt(graphData[seriesNumber][x.id][0]?.useCount)
						// 使用回数の軸の最大値を求める
						const useCountUsagePerfifty = Math.floor(useCountUsage / 50)
						if (useCountUsagePerfifty > maxColumn) {
							// 50で割った端数を表示するため50回分表示を増やす
							maxColumn = useCountUsagePerfifty + 1
						}
						dataSeries.push(Math.round(useCountUsage))
					} else {
						dataSeries.push(null)
					}
				} else {
					dataSeries.push(null)
				}
			} else {
				dataSeries.push(null)
			}
		}
		ColumnSeries.push({ name: x.name, type: 'column', data: dataSeries })
	})

	// 個室(vCore)、大便器、小便器、手洗い場(LIXIL)ごとに折れ線グラフデータを作成する
	ToiletAvailable.forEach((x: any) => {
		const dataSeries = []
		for (let i = 4; i <= 26; i++) {
			const m = i === 24 ? 0 : i === 25 ? 1 : i === 26 ? 2 : i
			const seriesNumber = m.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false,
			})

			// graphDataを04~02時間ごとに調べ、データがあったらグラフデータを作成する
			if (graphData[seriesNumber] !== undefined) {
				if (graphData[seriesNumber][x.id] !== undefined) {
					if (graphData[seriesNumber][x.id][0]?.minutes !== undefined) {
						const useCountMinute = parseInt(graphData[seriesNumber][x.id][0]?.minutes)
						if (useCountMinute > 0) {
							const usage = 60
							const percentUsage = (useCountMinute / usage) * 100
							const valueRounded = Math.round(percentUsage)
							dataSeries.push(valueRounded > 100 ? 100 : valueRounded)
						} else {
							dataSeries.push(0)
						}
					} else {
						dataSeries.push(0)
					}
				} else {
					dataSeries.push(0)
				}
			} else {
				dataSeries.push(0)
			}
		}
		LineSeries.push({ name: x.name, type: 'line', data: dataSeries })
	})

	ColumnSeries = _.orderBy(ColumnSeries, ['name'], ['asc'])
	LineSeries = _.orderBy(LineSeries, ['name'], ['asc'])
	SeriesArray = [...ColumnSeries, ...LineSeries]

	for (let i = 0; i < ToiletAvailable.length; i++) {
		if (i !== 0) {
			SeriesArrayHorizontal.push({
				show: false,
				max: maxColumn * 50,
				title: {
					text: '使用回数',
				},
			})
		} else {
			SeriesArrayHorizontal.push({
				max: maxColumn * 50,
				title: {
					text: '使用回数',
				},
			})
		}
	}

	for (let i = 0; i < ToiletAvailable.length; i++) {
		if (i !== 0) {
			SeriesArrayHorizontal.push({
				show: false,
				opposite: true,
				max: 100,
				title: {
					text: '使用率(%)',
				},
			})
		} else {
			SeriesArrayHorizontal.push({
				opposite: true,
				max: 100,
				title: {
					text: '使用率(%)',
				},
			})
		}
	}

	return {
		series: SeriesArray,
		yaxis: SeriesArrayHorizontal,
		colors: getRandomColor(SeriesArray),
	}
}
const getRandomColor = (data: any) => {
	const ColorArray = []
	const graph_line = []
	const graph_column = []
	const Colors = [
		'#E95CAB',
		'#64A7CC',
		'#E79B37',
		'#E9657D',
		'#B2CA41',
		'#1E3B8F',
		'#E85E25',
		'#622381',
		'#6B9645',
		'#6451AC',
		'#447AA1',
		'#9D2096',
		'#F1BF42',
		'#4E9977',
	]
	for (let x = 0; x < data.length; x++) {
		if (data[x].type === 'column') {
			graph_column.push(x)
			const index = (graph_column.length - 1) % (Colors.length)
			ColorArray[x] = Colors[index]
		} else {
			graph_line.push(x)
			const index = (graph_line.length - 1) % (Colors.length)
			ColorArray[x] = Colors[index]
		}
	}
	return ColorArray
}
