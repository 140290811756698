import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18fb8cbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-form" }
const _hoisted_2 = ["type", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "suffix"
}
const _hoisted_4 = {
  key: 0,
  class: "suffix-button-label"
}
const _hoisted_5 = {
  key: 1,
  class: "suffix-button-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass([_ctx.isError() ? 'c-label-error' : 'c-label'])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.isError() ? 'c-form-error-div' : 'c-form-div'])
      }, [
        _createVNode(_component_Field, {
          name: _ctx.name,
          type: _ctx.inputType,
          rules: _ctx.fieldRules,
          validateOnInput: true
        }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("input", _mergeProps(field, {
              autocomplete: "off",
              type: _ctx.inputType,
              placeholder: _ctx.placeholder,
              class: "c-form-input"
            }), null, 16, _hoisted_2)
          ]),
          _: 1
        }, 8, ["name", "type", "rules"]),
        (_ctx.type === 'password')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "suffix-button",
                type: "button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.passwordToggleVisible && _ctx.passwordToggleVisible(...args)))
              }, [
                (_ctx.passwordToggle)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " パスワードを隠す "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "パスワードを表示"))
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ], 64))
}