import _ from 'lodash'
import { Chart, Horizontal } from '@/components/types/series'

export const lineSeries = (graphData: any, toilets: any, cubicles: any) => {
	let isLixilUsed = false
	let LineSeries: Chart[] = []
	const SeriesArrayHorizontal: Horizontal[] = []

	for (let i = 0; i < graphData.length; i++) {
		for (let num = 4; num <= 26; num++) {
			const m = num === 24 ? 0 : num === 25 ? 1 : num === 26 ? 2 : num
			const seriesNumber = m.toLocaleString('en-US', {
				minimumIntegerDigits: 2,
				useGrouping: false,
			})
			if (graphData[i].stats[seriesNumber] !== undefined) {
				for (let j = 0; j < graphData[i].stats[seriesNumber].length; j++) {
					if (graphData[i].stats[seriesNumber][j].isDevice === true) {
						isLixilUsed = true
					}
				}
			}
		}
	}

	if (isLixilUsed === false) {
		for (let i = 0; i <= toilets?.length; i++) {
			if (toilets[i]) {
				if (!graphData.find((item: any) => parseInt(item.toiletFacilityId) === toilets[i].facilityId)) {
					graphData.push({
						toiletFacilityId: toilets[i].facilityId,
						toiletName: toilets[i].name,
						stats: {},
					})
				}
			}
		}

		for (let i = 0; i <= toilets?.length; i++) {
			if (toilets[i]) {
				graphData.forEach((item: any) => {
					if (parseInt(item.toiletFacilityId) === toilets[i].facilityId) {
						const cubiclesAvailable = cubicles.filter(
							(item: any) => item.toiletId === toilets[i].toiletId
						)

						if (cubiclesAvailable.length != 0) {
							item.graphCount = _.countBy(cubiclesAvailable, (item) => {
								return item.facilityType === 1
							}).true
						}
					}
				})
			}
		}


		for (let i = 0; i <= graphData.length; i++) {
			const LineObj = []
			if (graphData[i] !== undefined) {
				for (let j = 4; j <= 26; j++) {
					const m = j === 24 ? 0 : j === 25 ? 1 : j === 26 ? 2 : j
					const seriesNumber = m.toLocaleString('en-US', {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})
					if (graphData[i].stats[seriesNumber] !== undefined) {
						let sumUsageMinute = 0

						graphData[i].stats[seriesNumber].forEach((item: any) => {
							if ((item.minutes || 0) > 0) {
								sumUsageMinute += item.minutes || 0
							}
						})
						LineObj.push(
							Math.round(Math.round((sumUsageMinute / 60) * 100) / (graphData[i].graphCount || 1)) > 100
								? 100
								: Math.round(Math.round((sumUsageMinute / 60) * 100) / (graphData[i].graphCount || 1))
						)
					} else {
						LineObj.push(0)
					}
				}
				LineSeries.push({
					name: graphData[i].toiletName,
					type: 'line',
					data: LineObj,
					toiletMultipInfo: graphData[i].toiletMultipInfo
				})
			}
		}

		LineSeries = _.orderBy(LineSeries, ['name'], ['desc'])

		for (let i = 0; i < graphData.length; i++) {
			if (i !== 0) {
				SeriesArrayHorizontal.push({
					show: false,
					opposite: true,
					max: 100,
					title: {
						text: '使用率(%)',
					},
				})
			} else {
				SeriesArrayHorizontal.push({
					opposite: true,
					max: 100,
					title: {
						text: '使用率(%)',
					},
				})
			}
		}
	} else {
		let graphCount = 0

		for (let i = 0; i < graphData.length; i++) {
			const dataType: number[] = []
			const bowlMinutesData: number[] = []
			const urinalMinutesData: number[] = []
			const faucetMinutesData: number[] = []
			const typeCount: number[] = []
			for (let num = 4; num <= 26; num++) {
				const m = num === 24 ? 0 : num === 25 ? 1 : num === 26 ? 2 : num
				const seriesNumber = m.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
					useGrouping: false,
				})
				let bowlCount = 0
				let urinalCount = 0
				let faucetCount = 0
				let bowlUseMinutes = 0
				let urinalUseMinutes = 0
				let faucetUseMinutes = 0

				if (graphData[i].stats[seriesNumber] !== undefined) {
					for (let j = 0; j < graphData[i].stats[seriesNumber].length; j++) {
						// typeをdataTypeに格納する
						if (graphData[i].stats[seriesNumber][j].type !== undefined) {
							if (dataType[j] === undefined || dataType[j] === null) {
								dataType[j] = graphData[i].stats[seriesNumber][j].type
							}
							switch (graphData[i].stats[seriesNumber][j].type) {
								case 1:
									bowlCount++
									break;
								case 5:
									urinalCount++
									break;
								case 6:
									faucetCount++
									break;
								default:
									break;
							}

							// 使用率(minutes) / 60をlineDataArrayに格納する
							if (graphData[i].stats[seriesNumber][j].minutes !== undefined) {
								const useCountMinute = parseInt(graphData[i].stats[seriesNumber][j].minutes)
								if (useCountMinute > 0) {
									switch (graphData[i].stats[seriesNumber][j].type) {
										case 1:
											bowlUseMinutes += useCountMinute
											break;
										case 5:
											urinalUseMinutes += useCountMinute
											break;
										case 6:
											faucetUseMinutes += useCountMinute
											break;
										default:
											break;
									}
								}
							}
						}
					}
					if (typeCount.length === 0) {
						typeCount.push(bowlCount, urinalCount, faucetCount)
					}
				}
				bowlMinutesData.push(bowlUseMinutes)
				urinalMinutesData.push(urinalUseMinutes)
				faucetMinutesData.push(faucetUseMinutes)
			}
			// typeCount[j]の数だけCount,minutesを加算する
			for (let j = 0; j < typeCount.length; j++) {
				let name = graphData[i].toiletName
				switch (j) {
					case 0: {
						const tmpLineArray: number[] = []
						name = name + '個室'

						// 大便器の数、使用分数の合計から使用率を計算し、LineSeriesにpushする
						// 使用分数 / (60 * 個室数)を計算し、使用率を求める
						for (let k = 0; k < bowlMinutesData.length; k++) {
							const useCountMinute = bowlMinutesData[k]
							const usage = 60
							const percentUsage = useCountMinute / (usage * typeCount[0]) * 100
							const valueRounded = Math.round(percentUsage)
							tmpLineArray[k] = valueRounded > 100 ? 100 : valueRounded
						}
						LineSeries.push({ name: name, type: 'line', data: tmpLineArray, toiletMultipInfo: graphData[i].toiletMultipInfo })

						//yaxisを計算するためgraphCountをインクリメント
						graphCount++
						break;
					}
					case 1: {
						const tmpLineArray: number[] = []
						name = name + '小便器'

						// 大便器の数、使用分数の合計から使用率を計算し、LineSeriesにpushする
						// 使用分数 / 60を計算し、使用率を求める
						for (let k = 0; k < urinalMinutesData.length; k++) {
							if (tmpLineArray[k] == undefined || tmpLineArray[k] == null) {
								tmpLineArray[k] = 0
							}
							const useCountMinute = urinalMinutesData[k]
							const usage = 60
							const percentUsage = (useCountMinute / usage * typeCount[1]) * 100
							const valueRounded = Math.round(percentUsage)
							tmpLineArray[k] = valueRounded > 100 ? 100 : valueRounded
						}
						LineSeries.push({ name: name, type: 'line', data: tmpLineArray, toiletMultipInfo: graphData[i].toiletMultipInfo })

						//yaxisを計算するためgraphCountをインクリメント
						graphCount++
						break;
					}
					case 2: {
						const tmpLineArray: number[] = []
						name = name + '手洗い場'

						// 大便器の数、使用分数の合計から使用率を計算し、LineSeriesにpushする
						// 使用分数 / 60を計算し、使用率を求める
						for (let k = 0; k < faucetMinutesData.length; k++) {
							if (tmpLineArray[k] == undefined || tmpLineArray[k] == null) {
								tmpLineArray[k] = 0
							}
							const useCountMinute = faucetMinutesData[k]
							const usage = 60
							const percentUsage = (useCountMinute / usage * typeCount[2]) * 100
							const valueRounded = Math.round(percentUsage)
							tmpLineArray[k] = valueRounded > 100 ? 100 : valueRounded
						}
						LineSeries.push({ name: name, type: 'line', data: tmpLineArray, toiletMultipInfo: graphData[i].toiletMultipInfo })

						//yaxisを計算するためgraphCountをインクリメント
						graphCount++
						break;
					}
				}
			}
		}
		LineSeries = _.orderBy(LineSeries, ['name'], ['desc'])

		for (let i = 0; i < graphCount; i++) {
			if (i !== 0) {
				SeriesArrayHorizontal.push({
					show: false,
					opposite: true,
					max: 100,
					title: {
						text: '使用率(%)',
					},
				})
			} else {
				SeriesArrayHorizontal.push({
					opposite: true,
					max: 100,
					title: {
						text: '使用率(%)',
					},
				})
			}
		}
	}
	return {
		series: LineSeries,
		yaxis: SeriesArrayHorizontal,
		colors: getRandomColor(LineSeries),
	}
}
const getRandomColor = (data: any) => {
	const ColorArray = []
	const male_colors = ['#5F93D2', '#B2CA41', '#3ABFEB', '#4E9977', '#1E3B8F']
	const female_colors = ['#EE529D', '#F1B542', '#E12E2E', '#EB94BE', '#E47C1B']
	const multi_color = ['#622381', '#BE83E2', '#5C37C6', '#8E7AA7', '#9D2096']


	const male_toilets_line = []
	const female_toilets_line = []
	const multi_toilets_line = []

	for (let i = 0; i < data.length; i++) {
		if (data[i].toiletMultipInfo === 1) {
			male_toilets_line.push(i)
			const index = (male_toilets_line.length - 1) % (male_colors.length)
			ColorArray[i] = male_colors[index]
		}
		else if (data[i].toiletMultipInfo === 2) {
			female_toilets_line.push(i)
			const index = (female_toilets_line.length - 1) % (female_colors.length)
			ColorArray[i] = female_colors[index]
		}
		else if (data[i].toiletMultipInfo === 3) {
			multi_toilets_line.push(i)
			const index = (multi_toilets_line.length - 1) % (multi_color.length)
			ColorArray[i] = multi_color[index]
		}
	}
	return ColorArray

}