import { RouteRecordRaw } from "vue-router"
import LoginLayout from "@/layouts/LoginLayout.vue"

export default [
	{
		path: "/login",
		component: LoginLayout,
		children: [
			{
				path: "",
				name: "login-index",
				component: () => import("@/pages/login/index.vue"),
			},
		],
	},
] as Array<RouteRecordRaw>
