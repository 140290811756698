
import { defineComponent } from "vue"
import { RemoveIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentUsageCount",
	components: {
		RemoveIcon,
	},
	props: {
		count: {
			type: Number,
			default: 0,
		},
	},
})
