
import { defineComponent } from "vue"
import {
	RemoveIcon,
	WarningPaperIcon,
	RefillPaperIcon,
	WarningSoapIcon,
	RefillSoapIcon,
} from "@/utils/icons"

export default defineComponent({
	name: "ComponentStock",
	components: {
		RemoveIcon,
		WarningPaperIcon,
		RefillPaperIcon,
		WarningSoapIcon,
		RefillSoapIcon,
	},
	props: {
		facilityType: {
			type: Number,
			default: 1,
		},
		status: {
			type: String,
			default: "",
		},
		stockCount: {
			type: Number,
			default: 0,
		},
		capacityCount: {
			type: Number,
			default: 0,
		},
	},
})
