
import { defineComponent } from "vue"
import { RemoveIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentCubiclesAlert",
	components: {
		RemoveIcon,
	},
	props: {
		alertArray: {
			type: Array,
			default: () => [],
		},
	},
})
