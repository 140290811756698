
import { defineComponent } from "vue"

export default defineComponent({
	name: "ComponentAlert",
	props: {
		alert: {
			type: Array,
			default: () => [],
		},
	},
})
