
import { defineComponent } from "vue"
import { RemoveIcon, WarningSoapIcon, RefillSoapIcon } from "@/utils/icons"

export default defineComponent({
	name: "ComponentSoap",
	components: {
		RemoveIcon,
		WarningSoapIcon,
		RefillSoapIcon,
	},
	props: {
		soap: {
			type: String,
			default: "normal",
		},
		soapStockCount: {
			type: Number,
			default: 0,
		},
		soapCapacityCount: {
			type: Number,
			default: 0,
		},
	},
})
