
// @ts-check
import { defineComponent, PropType } from "vue"
import { TableCol } from "./types/table"

export default defineComponent({
	name: "ComponentTable",
	props: {
		headers: {
			type: Array as PropType<TableCol[]>,
			default: () => [],
		},
		tableData: {
			type: Array,
			default: () => [],
		},
		tableWidth: {
			type: String,
			default: "100%",
		},
		tableHeight: {
			type: String,
			default: "auto",
		},
		isStation: {
			type: Boolean,
			default: false,
		},
		customHeaderHeight: {
			type: Number,
			default: null,
		},
	},
	methods: {
		headerHeight(): any {
			if (this.customHeaderHeight) {
				return `${this.customHeaderHeight}px`
			}

			return null
		},
	},
})
