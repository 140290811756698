
import { defineComponent } from "vue"
import { Series } from "@/utils/series"
import { columnSeries } from "@/utils/columnSeries"
import { lineSeries } from "@/utils/lineSeries"

import { Chart, registerables } from "chart.js"
import { Bar } from "vue-chartjs"
import zoomPlugin from "chartjs-plugin-zoom"

Chart.register(...registerables)
Chart.register(zoomPlugin)

export default defineComponent({
	name: "StationChartJs",
	components: {
		Bar,
	},
	props: {
		graphData: {
			type: Array,
			require: true,
		},
		toilets: {
			type: Array,
			require: true,
		},
		cubicles: {
			type: Array,
			require: true,
		},
		graphType: {
			type: Number,
			require: true,
		},
	},
	data() {
		let data
		let SeriesObj = {
			series: [] as any,
			yaxis: [] as any,
			colors: [] as any,
		}
		let options
		let scales
		switch (this.graphType) {
			case 0:
			case 3:
				SeriesObj = Series(this.graphData, this.toilets, this.cubicles)
				scales = {
					y: {
						display: true,
						suggestedMin: 0,
						suggestedMax: SeriesObj.yaxis[0].max,
						beginAtZero: true,
						position: "left",
						title: {
							display: true,
							text: "使用回数",
						},
						grid: {
							display: false,
						},
					},
					y1: {
						type: "linear",
						display: true,
						suggestedMin: 0,
						suggestedMax: 100,
						beginAtZero: true,
						position: "right",
						title: {
							display: true,
							text: "使用率(%)",
						},
					},
				}
				break
			case 1:
				SeriesObj = lineSeries(this.graphData, this.toilets, this.cubicles)
				scales = {
					y1: {
						type: "linear",
						display: true,
						suggestedMin: 0,
						suggestedMax: 100,
						beginAtZero: true,
						position: "right",
						title: {
							display: true,
							text: "使用率(%)",
						},
					},
				}
				break
			case 2:
				SeriesObj = columnSeries(this.graphData, this.toilets, this.cubicles)
				scales = {
					y: {
						display: true,
						suggestedMin: 0,
						suggestedMax: SeriesObj.yaxis[0].max,
						beginAtZero: true,
						position: "left",
						title: {
							display: true,
							text: "使用回数",
						},
						grid: {
							display: true,
						},
					},
				}
				break
		}
		options = {
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				mode: "index",
				intersect: false,
			},
			stacked: false,
			plugins: {
				title: {
					display: false,
				},
				legend: {
					position: "bottom",
				},
				tooltip: {
					enabled: false,
				},
				zoom: {
					pan: {
						enabled: true,
						mode: "x",
						modifierKey: "shift",
						threshold: 20,
					},
					zoom: {
						wheel: {
							enabled: true,
							speed: 0.001,
						},
						drag: {
							enabled: true,
							backgroundColor: "rgba(225,225,225,0.5)",
						},
						pinch: {
							enabled: true,
						},

						mode: "x",
					},
				},
			},
			scales: {
				x: {
					border: {
						display: false,
					},
					grid: {
						display: false,
						drawOnChartArea: false,
						drawTicks: true,
					},
				},
				...scales,
			},
		}
		data = {
			labels: [
				"04:00",
				"05:00",
				"06:00",
				"07:00",
				"08:00",
				"09:00",
				"10:00",
				"11:00",
				"12:00",
				"13:00",
				"14:00",
				"15:00",
				"16:00",
				"17:00",
				"18:00",
				"19:00",
				"20:00",
				"21:00",
				"22:00",
				"23:00",
				"00:00",
				"01:00",
				"02:00",
			],
			datasets: [] as any,
		}
		for (let i = 0; i < SeriesObj.series.length; i++) {
			let name
			let array = [] as any
			let bgcolor
			let isColumn = false
			let obj
			if (SeriesObj.series[i].data.length === 0) {
				SeriesObj.series[i].data = [
					0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				]
			}
			for (let j = 0; j < SeriesObj.series[i].data.length; j++) {
				if (SeriesObj.series[i].type === "column") {
					name = SeriesObj.series[i].name
					array[j] = SeriesObj.series[i].data[j]
					bgcolor = SeriesObj.colors[i]
					isColumn = true
				} else {
					name = SeriesObj.series[i].name
					array[j] = SeriesObj.series[i].data[j]
					bgcolor = SeriesObj.colors[i]
				}
			}
			if (isColumn) {
				obj = {
					label: name,
					data: array,
					backgroundColor: bgcolor,
					yAxisID: "y",
				}
			} else {
				obj = {
					type: "line",
					label: name,
					data: array,
					borderColor: bgcolor,
					backgroundColor: bgcolor,
					yAxisID: "y1",
				}
			}
			data.datasets.push(obj)
		}
		return {
			data,
			options,
		}
	},
})
